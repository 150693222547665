/* eslint-disable react/button-has-type */
import React, { ButtonHTMLAttributes, CSSProperties, ReactNode } from "react"
import { NavLink } from "react-router-dom"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"
import { rem } from "utils/styles"
import { useMst } from "models/Root"

import AlphaIcon from "components/redesigned/AlphaIcon"

import styles from "./miniButton.module.scss"

interface IProps {
	caption?: string | number
	type?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
	icon?: ReactNode | TAlphaIcon
	iconSize?: number
	bordered?: boolean
	disabled?: boolean
	className?: string
	to?: string
	onClick?: ButtonHTMLAttributes<HTMLButtonElement>["onClick"]
}

const MiniButton: React.FC<IProps> = ({
	caption,
	type = "button",
	icon,
	iconSize,
	bordered,
	disabled,
	className,
	to,
	onClick,
}) => {
	const {
		global: { locale },
	} = useMst()

	const style: Record<string, string> = {}
	if (iconSize) style["--icon-size"] = rem(iconSize)

	const button = (
		<button
			type={type}
			style={Object.keys(style).length ? (style as CSSProperties) : undefined}
			className={cn(styles.miniButton, { [styles.bordered]: bordered }, className)}
			disabled={disabled}
			onClick={onClick}
		>
			{typeof icon === "string" ? (
				icon.startsWith("ai") ? (
					<AlphaIcon name={icon as TAlphaIcon} />
				) : (
					<img src={icon} alt={`${caption || ""}`} />
				)
			) : (
				icon
			)}
			{!!caption && <span>{caption}</span>}
		</button>
	)

	if (!to) return button

	const formattedTo = to.startsWith(`/${locale}`) ? to : `/${locale}${to}`

	return <NavLink to={formattedTo}>{button}</NavLink>
}

export default MiniButton
