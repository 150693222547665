import { useState } from "react"

import errorHandler from "utils/errorHandler"

const useAsyncAction = (
	action: () => Promise<void> | void,
	onError?: (error: any) => Promise<void> | void,
): [() => Promise<void>, boolean] => {
	const [loading, setLoading] = useState<boolean>(false)

	const handler = async () => {
		try {
			setLoading(true)
			await action()
		} catch (error) {
			await onError?.((error as any)?.data)
			errorHandler(error, false)
		} finally {
			setLoading(false)
		}
	}

	return [handler, loading]
}

export default useAsyncAction
