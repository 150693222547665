import { makeAutoObservable } from "mobx"

import WalletStore from "./wallet"

export default class AccountStore {
	wallet

	constructor() {
		this.wallet = new WalletStore()

		makeAutoObservable(this)
	}
}
