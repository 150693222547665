/* eslint-disable no-unused-expressions,no-void */
import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { EBybitWalletType, EMarginTransferStatus, getEBybitWalletTypeLabel } from "types/account"
import { useMobxStore } from "store"
import commonMessages from "messages/common"
import exchangeMessages from "messages/exchange"
import { TDefaultModalProps } from "hooks/useModal"
import useDidUpdate from "hooks/useDidUpdate"
import useAsyncAction from "hooks/useAsyncAction"
import AccountService from "services/AccountService"

import Field from "components/redesigned/Field"
import Select, { TSelectOption } from "components/redesigned/Select"
import Input from "components/redesigned/Input"
import MiniButton from "components/redesigned/MiniButton"
import Button from "components/redesigned/Button"
import CurrencyIcon from "components/redesigned/CurrencyIcon"

import SwitchHorizontalIcon from "assets/icons/SwitchHorizontal"

import styles from "./wallets.module.scss"

export type TTransferData = {
	from: EBybitWalletType
	to: EBybitWalletType
	coin: string
	amount: number
}

type TParams = {
	from: EBybitWalletType
	coin?: string
}

type TProps = TDefaultModalProps & TParams

const Transfer: React.FC<TProps> = ({ from, coin = "", closeModal, updateModal }) => {
	const { formatMessage } = useIntl()
	const {
		account: { wallet },
	} = useMobxStore()

	const getNextType = (type: EBybitWalletType): EBybitWalletType =>
		Object.values(EBybitWalletType).filter(item => item !== type)[0]

	const [data, setData] = useState<TTransferData>({
		from,
		to: getNextType(from),
		coin,
		amount: NaN,
	})
	const updateData = (values: Partial<TTransferData>) =>
		setData(current => ({ ...current, ...values }))

	useDidUpdate(() => updateData({ coin: "" }), [data.from])
	useEffect(() => updateData({ amount: NaN }), [data.coin])

	const typesOptions = useMemo<TSelectOption[]>(
		() =>
			Object.values(EBybitWalletType).map(type => ({
				label: getEBybitWalletTypeLabel(type, formatMessage),
				value: type,
			})),
		[formatMessage],
	)

	const balance = data.from === EBybitWalletType.FUND ? wallet.fund.list : wallet.unified.list

	const coinsOptions = useMemo<TSelectOption[]>(
		() =>
			balance
				.filter(({ available }) => available)
				.map(({ code, available }) => ({
					label: code,
					icon: <CurrencyIcon name={code} />,
					amount: available,
					value: code,
				})),
		[balance],
	)

	const available = useMemo<number>(
		() => balance.find(({ code }) => code === data.coin)?.available || 0,
		[data.coin],
	)

	const [handleSubmit, loading] = useAsyncAction(async () => {
		const result = await AccountService.marginTransfer({
			from_account_type: data.from,
			to_account_type: data.to,
			currency: data.coin,
			amount: data.amount.toString(),
		})
		if (result.status !== EMarginTransferStatus.SUCCESS)
			//TODO: translate
			throw new Error("Something went wrong")
		await wallet.fund.load()
		closeModal()
	})

	useEffect(() => updateModal({ closeEsc: !loading, closeButton: !loading }), [loading])

	return (
		<div className={styles.transfer}>
			<div className={styles.direction}>
				<Field
					fComponent={Select}
					fLabel={formatMessage(exchangeMessages.from)}
					fClassName={styles.type}
					options={typesOptions}
					value={data.from}
					onChange={(from: EBybitWalletType) => updateData({ from, to: getNextType(from) })}
				/>
				<MiniButton
					icon={<SwitchHorizontalIcon size={20} />}
					className={styles.switch}
					onClick={() => updateData({ from: data.to, to: data.from })}
				/>
				<Field
					fComponent={Select}
					fLabel={formatMessage(exchangeMessages.to)}
					fClassName={styles.type}
					options={typesOptions}
					value={data.to}
					onChange={(to: EBybitWalletType) => updateData({ to, from: getNextType(to) })}
				/>
			</div>
			<Field
				fComponent={Select}
				fLabel={formatMessage(exchangeMessages.currency)}
				options={coinsOptions}
				search
				value={data.coin}
				onChange={(coin: string) => updateData({ coin })}
			/>
			<Field
				fComponent={Input}
				fLabel={formatMessage(exchangeMessages.amount)}
				fRequired
				fSubline={`Max ${available} ${data.coin || ""}`}
				placeholder="Type amount"
				number
				min={0}
				max={available}
				disabled={!data.coin}
				value={Number.isNaN(data.amount) ? "" : data.amount.toString()}
				onChange={value => updateData({ amount: value.length ? +value : NaN })}
				action={formatMessage(commonMessages.all)}
				actionClick={() => updateData({ amount: available })}
			/>
			<footer>
				<Button
					kind="outlined"
					caption={formatMessage(commonMessages.cancel)}
					disabled={loading}
					onClick={closeModal}
				/>
				<Button
					caption={formatMessage(commonMessages.confirm)}
					disabled={!data.amount}
					loading={loading}
					onClick={handleSubmit}
				/>
			</footer>
		</div>
	)
}

export default Transfer
