import React, { FC } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import styles from "components/NewUI/Header/Navigation/index.module.scss"
import NavArrowRight from "assets/icons/header/NavArrowRight"
import InternalLink from "components/InternalLink"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import { routes } from "constants/routing"
import { useMst } from "models/Root"
import Deposit from "assets/icons/header/Deposit"
import Withdraw from "assets/icons/header/Withdraw"
import WalletValue from "./WalletValue"

const Wallet: FC = () => {
	const { formatMessage } = useIntl()
	const {
		account: { isDepositEnabled, isWithdrawEnabled, isTransferEnabled, isAlphaCodeEnabled },
	} = useMst()

	return (
		<div className={`${styles.nav__firstList} ${styles.nav__firstList___wallet}`}>
			<WalletValue />

			<p className={styles.nav__dataText}>{formatMessage(commonMessages.dataMayBeDelayed)}</p>

			<div className={styles.nav__depositWithdrawButtons}>
				{isDepositEnabled ? (
					<InternalLink to={routes.profile.createDeposit} className={styles.nav__depositButton}>
						<Deposit />

						<span>{formatMessage(commonMessages.deposit)}</span>
					</InternalLink>
				) : null}

				{isWithdrawEnabled ? (
					<InternalLink to={routes.profile.createWithdraw} className={styles.nav__withdrawButton}>
						<Withdraw />

						<span>{formatMessage(commonMessages.withdraw)}</span>
					</InternalLink>
				) : null}
			</div>

			<ul className={styles.nav__userList}>
				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.profile.walletOverview} className={styles.nav__userButton}>
						<span>{formatMessage(commonMessages.overview)}</span>

						<NavArrowRight />
					</InternalLink>
				</li>

				{/*{isTransferEnabled ? (*/}
				{/*	<li className={styles.nav__itemFirst}>*/}
				{/*		<InternalLink to={routes.transfers.root} className={styles.nav__userButton}>*/}
				{/*			<span>{formatMessage(financeMessages.transfer)}</span>*/}

				{/*			<NavArrowRight />*/}
				{/*		</InternalLink>*/}
				{/*	</li>*/}
				{/*) : null}*/}

				{/*{isAlphaCodeEnabled && alphaCode ? (*/}
				{/*	<li className={styles.nav__itemFirst}>*/}
				{/*		<InternalLink to={routes.alphaCodes.root} className={styles.nav__userButton}>*/}
				{/*			<span>{formatMessage(commonMessages.alpha_codes)}</span>*/}

				{/*			<NavArrowRight />*/}
				{/*		</InternalLink>*/}
				{/*	</li>*/}
				{/*) : null}*/}

				<li className={styles.nav__itemFirst}>
					<InternalLink to={routes.financeHistory.root} className={styles.nav__userButton}>
						<span>{formatMessage(commonMessages.history)}</span>

						<NavArrowRight />
					</InternalLink>
				</li>
			</ul>
		</div>
	)
}

export default observer(Wallet)
