/* eslint-disable no-unused-expressions,no-void */
import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { IGetDepositAddressResponse, TDepositMethod } from "types/deposit"
import { routes } from "constants/routing"
import commonMessages from "messages/common"
import { useMobxStore } from "store"
import { useMst } from "models/Root"
import useLocaleNavigate from "hooks/useLocaleNavigate"
import DepositService from "services/DepositService"
import errorHandler from "utils/errorHandler"

import Stepper from "components/redesigned/Stepper"
import Select, { TSelectChangeEvent, TSelectOption } from "components/redesigned/Select"
import CurrencyIcon from "components/redesigned/CurrencyIcon"
import KeyValues from "components/redesigned/KeyValues"
import LoadingSpinner from "components/UI/LoadingSpinner"
import Billboard from "components/redesigned/Billboard"
import QRPanel from "components/redesigned/QRPanel"
import Button from "components/redesigned/Button"

import styles from "./deposit.module.scss"

const DEFAULT_COINS: string[] = ["BTC", "ETH", "USDT", "USDC", "TON"]

type TProps = {
	currency: string
}

const DepositForm: React.FC<TProps> = ({ currency }) => {
	const { formatMessage, formatNumber } = useIntl()
	const {
		account: { profileStatus },
	} = useMst()
	const {
		account: { wallet },
	} = useMobxStore()
	const localeNavigate = useLocaleNavigate()

	const currencies = wallet.fund.list

	const [step, setStep] = useState<number>(1)

	/* Coin */

	const currencyOptions = useMemo(
		() =>
			[...currencies]
				.sort((a, b) => {
					if (!a.available !== !b.available) return b.available - a.available
					return a.code.localeCompare(b.code)
				})
				.map<TSelectOption>(({ code, available }) => ({
					icon: <CurrencyIcon name={code} />,
					label: code,
					description: code,
					amount: formatNumber(+available, { minimumFractionDigits: 2, maximumFractionDigits: 8 }),
					value: code.toUpperCase(),
				})),
		[currencies.length],
	)

	const handleCurrencyChange: TSelectChangeEvent = value => {
		if (!value) {
			setStep(0)
			return
		}
		currency !== value && localeNavigate(routes.profile.getDepositCurrency(value))
		setStep(1)
	}

	/* Chain */

	const [methods, setMethods] = useState<TDepositMethod[]>([])
	const [methodId, setMethodId] = useState<string>("")

	const loadMethods = async () => {
		if (!currency) return
		try {
			const result = await DepositService.getMethods({ currency })
			setMethods(result)
		} catch (error) {
			errorHandler(error, false)
		}
	}
	useEffect(() => {
		if (step < 1) {
			setMethods([])
			setMethodId("")
		} else if (currency && step === 1) {
			setMethods([])
			setMethodId("")
			loadMethods()
		}
	}, [currency])

	const methodOptions = useMemo(
		() =>
			methods.map<TSelectOption>(({ id, network, is_deposit_enabled }) => ({
				label: network.name,
				value: id,
				disabled: !is_deposit_enabled,
			})),
		[methods],
	)

	/* Requisites */

	const method = methods.find(({ id }) => id === methodId)
	const { min_deposit = 0, deposit_confirmations_need = 0, min_verification_level } = method || {}

	const [requisites, setRequisites] = useState<IGetDepositAddressResponse["chains"]>()
	const [loading, setLoading] = useState<boolean>(false)
	const isTag = !!requisites?.tag_deposit

	const loadAddress = async () => {
		if (!method) return
		try {
			setLoading(true)
			setRequisites(undefined)
			const { chains } = await DepositService.getDepositAddress({ deposit_method: method.id })
			setRequisites(chains)
		} catch (error) {
			errorHandler(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!method) return
		loadAddress()
		setStep(2)
	}, [method])

	/* Render */

	//TODO: render verification level if false
	const isEnoughVerificationLevel =
		(profileStatus?.verification_level ?? 0) >= (min_verification_level ?? 0)

	return (
		<Stepper step={step} className={styles.form}>
			{/*TODO: translate*/}
			<Stepper.Item title="Select coin to deposit" className={styles.step}>
				<Select
					controlClassName={styles.currencies}
					//TODO: translate
					placeholder="Select Coin"
					options={currencyOptions}
					search
					value={currency}
					loading={!currencies?.length}
					onChange={handleCurrencyChange}
				/>
				{!!currencies?.length && !currency && (
					<div className={styles.defaults}>
						{DEFAULT_COINS.map(coin => (
							<Button
								key={`deposit-coin-${coin}`}
								caption={coin}
								icon={<CurrencyIcon name={coin} />}
								size="mini"
								kind="secondary"
								disabled={coin === currency}
								onClick={() => handleCurrencyChange(coin)}
							/>
						))}
					</div>
				)}
			</Stepper.Item>
			{/*TODO: translate*/}
			<Stepper.Item title="Select a Chain" className={styles.step}>
				<Select
					//TODO: translate
					placeholder="Chain Type"
					loading={!methods.length}
					options={methodOptions}
					value={methodId}
					onChange={value => setMethodId(value || "")}
				/>
			</Stepper.Item>
			{/*TODO: translate*/}
			<Stepper.Item title="Confirm deposit details">
				{loading ? (
					<LoadingSpinner />
				) : !requisites ? (
					<p className={styles.error}>Something went wrong</p>
				) : (
					<>
						{isTag && (
							<Billboard
								//TODO: translate
								text={[
									`Please fill in both the deposit address and Tag/Memo correctly when depositing ${method?.network?.name}. Otherwise your funds will be lost and cannot be recovered.`,
									"Deposits may be delayed due to blockchain congestion or airdrop distribution. Typically, if your information is correct, deposits will still be auto-credited, so please wait patiently.",
								]}
								bordered
							/>
						)}
						<QRPanel
							title={`${currency} ${formatMessage(commonMessages.address)}`}
							code={requisites.address_deposit}
							className={styles.qr}
						/>
						{isTag && (
							<QRPanel
								title={`${currency} Tag/Memo`}
								code={requisites.tag_deposit}
								className={styles.qr}
							/>
						)}
						<KeyValues
							className={styles.values}
							items={[
								{
									//TODO: translate
									key: "Minimum Deposit Amount",
									value: `${formatNumber(+min_deposit, {
										maximumFractionDigits: 8,
										useGrouping: false,
									})} ${currency}`,
									separated: !(+requisites.batch_release_limit > 0),
								},
								{
									//TODO: translate
									key: "Maximum Deposit Amount",
									value: `${formatNumber(+requisites.batch_release_limit, {
										maximumFractionDigits: 8,
										useGrouping: false,
									})} ${currency}`,
									isVisible: +requisites.batch_release_limit > 0,
									separated: true,
								},
								{
									//TODO: translate
									key: "Deposits auto channeled to",
									value: `Funding`,
								},
								{
									//TODO: translate
									key: "Deposit Arrival",
									value: `${deposit_confirmations_need} confirmations`,
								},
								{
									//TODO: translate
									key: "Withdrawal Unlocked",
									value: `${deposit_confirmations_need} confirmations`,
									separated: true,
								},
							]}
						/>
						<div className={styles.text}>
							{/*TODO: translate*/}
							<span>Important:</span>
							<p>
								Please make sure that only {currency} deposit is made via this address. Otherwise,
								your deposited funds will not be added to your available balance — nor will it be
								refunded.
							</p>
							<p>For successful deposit, please make sure the wallet address is accurate.</p>
							<p>
								If the current address is used, {deposit_confirmations_need} block confirmations are
								required before your deposited funds can be added to your available balance.
							</p>
							<p>
								Please note that the current asset does not support deposit via the smart contract.
								If used, your deposited funds will not be added to your available balance — nor will
								it be refunded.
							</p>
						</div>
						<Billboard
							className={styles.full}
							//TODO: translate
							text="To maintain our platform's integrity and safety, BTC-Alpha is committed to fighting financial crime and enforcing anti-money laundering measures."
						/>
					</>
				)}
			</Stepper.Item>
		</Stepper>
	)
}

export default observer(DepositForm)
