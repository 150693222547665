/* eslint-disable no-unused-expressions */
import React from "react"
import { useIntl } from "react-intl"

import { EBybitWalletType, TBalanceCoin } from "types/account"
import { FORMAT_NUMBER_OPTIONS_USD } from "constants/format"
import { routes } from "constants/routing"
import { STARS_STR } from "constants/wallet"
import { useMobxStore } from "store"
import commonMessages from "messages/common"
import financeMessages from "messages/finance"
import useLocaleNavigate from "hooks/useLocaleNavigate"

import {
	ETableColumnAlign,
	ETableFilterType,
	TTableColumns,
	TTableFilters,
} from "components/redesigned/Table"
import CurrencyIcon from "components/redesigned/CurrencyIcon"

import SwitchHorizontalIcon from "assets/icons/SwitchHorizontal"

import styles from "./wallets.module.scss"

const FORMAT_NUMBER_OPTIONS = {
	useGrouping: false,
	minimumFractionDigits: 2,
	maximumFractionDigits: 8,
}

export const useColumns = ({
	type,
	onTransferClick,
}: {
	type: EBybitWalletType
	onTransferClick: (coin: string) => void
}): TTableColumns<TBalanceCoin> => {
	const { formatNumber, formatMessage } = useIntl()
	const localeNavigate = useLocaleNavigate()
	const {
		account: { wallet },
	} = useMobxStore()

	const renderVisibility = (value: number | string) => (wallet.isVisible ? value : STARS_STR)

	const renderConverted = (value: number, rate = 1) =>
		`≈ $${renderVisibility(formatNumber(value * rate || 0, FORMAT_NUMBER_OPTIONS_USD))}`

	return [
		{
			key: "code",
			//TODO: translate
			caption: "Currency",
			align: ETableColumnAlign.MIDDLE,
			className: styles.currency,
			render: ({ code }) => (
				<>
					<CurrencyIcon name={code} size={32} />
					<span>{code}</span>
				</>
			),
		},
		{
			key: "balance",
			//TODO: translate
			caption: "Equity",
			render: ({ balance }) => renderVisibility(formatNumber(balance, FORMAT_NUMBER_OPTIONS)),
			subline: ({ converted }) => renderConverted(converted.USD),
		},
		{
			key: "available",
			caption: formatMessage(financeMessages.available),
			render: ({ available }) => renderVisibility(formatNumber(available, FORMAT_NUMBER_OPTIONS)),
			subline: ({ available, balance, converted }) =>
				renderConverted(available, converted.USD / balance),
		},
		{
			key: "reserve",
			//TODO: translate
			caption: "Frozen",
			render: ({ reserve }) => renderVisibility(formatNumber(reserve, FORMAT_NUMBER_OPTIONS)),
			subline: ({ reserve, balance, converted }) =>
				renderConverted(reserve, converted.USD / balance),
		},
		{
			key: "actions",
			align: ETableColumnAlign.MIDDLE,
			width: 1,
			caption: formatMessage(commonMessages.action),
			className: styles.actions,
			actions: ({ code, available }) => [
				{
					icon: "ai-credit-card-upload",
					caption: formatMessage(commonMessages.deposit),
					visible: type === EBybitWalletType.FUND,
					to: routes.profile.getDepositCurrency(code),
				},
				{
					icon: "ai-credit-card-download",
					disabled: !available,
					visible: type === EBybitWalletType.FUND,
					to: routes.profile.getWithdrawCurrency(code),
				},
				{
					icon: <SwitchHorizontalIcon size={16} />,
					disabled: !available,
					onClick: ({ code }) => onTransferClick(code),
				},
			],
		},
	]
}

export const useFilters = (): TTableFilters<TBalanceCoin> => {
	const { formatMessage } = useIntl()
	return [
		{
			key: "code",
			type: ETableFilterType.STRING,
			icon: "ai-search",
			placeholder: formatMessage(commonMessages.search),
		},
		{
			key: "balance",
			type: ETableFilterType.CHECKBOX,
			caption: formatMessage(financeMessages.hide_empty_balances),
			toRight: true,
		},
	]
}
