/* eslint-disable import/no-cycle */
import { ECalendarType, EWeekDay, TDayNames, TMonthNames } from "./types";

export const defaultProps = {
  firstWeekDay: EWeekDay.SUN,
  weekHeight: 36,
  dayHeight: 36,
  daysPadding: 16,
  monthHeight: 36,
  yearWidth: 64,
  monthsPadding: [8, 16],
  selectedRadius: 8,
  calendarType: ECalendarType.DAY,
};

export const defaultColors = {
  background: "#101014",
  weekBackground: "#19191D",
  weekText: "#EBEBF5A3",
  borders: "#403F455C",
  monthName: "#fff",
  yearName: "#fff",
  daysDayText: "#fff",
  monthsMonthText: "#fff",
  monthsYearText: "#fff",
  monthsYearBackground: "#101014",
  yearsYearText: "#fff",
  todayBorder: "rgba(64,63,69,0.8)",
  hoveredDayBackground: "rgba(182,167,241,0.4)",
  selectedHoveredDayBackground: "#9b83fa",
  betweenSelectedDaysBackground: "#7C59FF33",
  selectedDayBackground: "#7C59FF",
  selectedDayText: "#fff",
};

export const defaultFonts = {
  family: "Roboto, sans-serif",
  daysWeek: 14,
  daysDay: 14,
  daysMonth: 14,
  monthsMonth: 14,
  monthsYear: 14,
};

export const defaultDayNames: TDayNames = {
  [EWeekDay.SUN]: "Su",
  [EWeekDay.MON]: "Mo",
  [EWeekDay.TUE]: "Tu",
  [EWeekDay.WED]: "We",
  [EWeekDay.THU]: "Th",
  [EWeekDay.FRI]: "Fr",
  [EWeekDay.SAT]: "Sa",
};

export const defaultMonthNames: TMonthNames = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const defaultMonthShortNames: TMonthNames = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};
