import { makeAutoObservable } from "mobx"

import { OrderBookStore } from "./orderBook"
import { CurrentPairStore } from "./currentPair"
import TickersStore from "./tickers"

export class TerminalStore {
	orderBook
	currentPair
	tickers

	constructor() {
		this.orderBook = new OrderBookStore()
		this.currentPair = new CurrentPairStore()
		this.tickers = new TickersStore()

		makeAutoObservable(this)
	}
}
