import React, { useId } from "react"
import { toast, ToastOptions } from "react-toastify"

import IconSuccess from "assets/icons/toast/Success"
import IconInformational from "assets/icons/toast/Informational"
import IconError from "assets/icons/toast/Error"

type TToastMessage = string | string[]

const DEFAULT_OPTIONS: ToastOptions = {
	position: "bottom-left",
	autoClose: 5_000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "dark",
}

const Message: React.FC<{ title: string; message?: TToastMessage }> = ({ title, message }) => {
	const id = useId()
	return (
		<div>
			<div>{title}</div>
			{message && (
				<div className="Toastify__toast-subtext">
					{!Array.isArray(message)
						? message
						: message.map((line, index) => (
								<React.Fragment key={`toast-message-${id}-line-${index.toString()}`}>
									{line}
									{index < message.length - 1 && <br />}
								</React.Fragment>
						  ))}
				</div>
			)}
		</div>
	)
}

export const messageSuccess = (message?: TToastMessage) =>
	toast.success(<Message title="Success" message={message} />, {
		...DEFAULT_OPTIONS,
		icon: <IconSuccess />,
	})

export const messageInfo = (message?: TToastMessage) =>
	toast.info(<Message title="Informational" message={message} />, {
		...DEFAULT_OPTIONS,
		icon: <IconInformational />,
	})

export const messageError = (message?: TToastMessage) =>
	toast.error(<Message title="Error" message={message} />, {
		...DEFAULT_OPTIONS,
		icon: <IconError />,
	})
