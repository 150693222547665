import React, { ComponentProps, PropsWithChildren } from "react"
import Helmet from "react-helmet"

import { routes } from "constants/routing"
import useWindowSize from "hooks/useWindowSize"
import usePathname from "hooks/usePathname"

import Cookies from "components/Cookies"
import Header from "components/NewUI/Header"

import Wallet from "./menus/Wallet"
import Orders from "./menus/Orders"

import styles from "./redesignedLayout.module.scss"

interface IProps extends PropsWithChildren {
	title?: string
	className?: string
	helmet?: ComponentProps<typeof Helmet>
}

const menus = {
	[routes.profile.wallets]: Wallet,
	[routes.history.root]: Orders,
}

const RedesignedLayout: React.FC<IProps> = ({ title, helmet, children, className }) => {
	const { desktop } = useWindowSize()
	const pathname = usePathname()

	const Menu = Object.entries(menus).find(([route, component]) =>
		`/${pathname}`.startsWith(route),
	)?.[1]

	return (
		<>
			{helmet && <Helmet {...helmet} />}
			<Header className={styles.header} />
			<div className={styles.layout}>
				{Menu && desktop && (
					<div className={styles.sideBar}>
						<Menu />
					</div>
				)}
				<div className={styles.content}>
					{title && <h1>{title}</h1>}
					<div className={className}>{children}</div>
					<footer>
						{/*TODO: translate*/}
						<span>Alphacom UAB, J. Savickio g. 4-7, LT-01108 Vilnius</span>
						<span>© 2024 BTC-Alpha</span>
					</footer>
				</div>
			</div>
			<Cookies />
		</>
	)
}

export default RedesignedLayout
