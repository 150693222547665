/* eslint-disable consistent-return */
import errorHandler from "./errorHandler"

export function handleError(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
	const originalMethod = descriptor.value

	descriptor.value = async function (...args: any[]) {
		try {
			return await originalMethod.apply(this, args)
		} catch (err) {
			errorHandler(err)
		}
	}

	return descriptor
}
