/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react"
import { useIntl } from "react-intl"

import { EOrderSide, EOrderType } from "types/orders"
import { useMst } from "models/Root"
import { IOrder } from "models/History"
import historyMessages from "messages/history"
import exchangeMessages from "messages/exchange"
import ExchangeService from "services/ExchangeService"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION, PERCENT_PRECISION } from "utils/constants"
import { formatDate } from "utils/formatDate"
import { capitalizeFirstLetter } from "utils/reportUtils"
import errorHandler from "utils/errorHandler"
import { messageSuccess } from "utils/messages"

import { ETableFilterType, TTableColumns, TTableFilters } from "components/redesigned/Table"
import Badge, { BadgeColorEnum } from "components/UI/Badge"

const directionColor: Record<EOrderSide, BadgeColorEnum> = {
	[EOrderSide.BUY]: BadgeColorEnum.GREEN,
	[EOrderSide.SELL]: BadgeColorEnum.RED,
}

export const useColumns = (refetch?: () => void): TTableColumns<IOrder> => {
	const { formatNumber, formatMessage } = useIntl()

	const getFormatedNumber = (value: number | null, decimal: number = MAX_PRICE_PRECISION) =>
		formatNumber(value || 0, {
			minimumFractionDigits: MIN_PRICE_PRECISION,
			maximumFractionDigits: Math.max(Math.log10(decimal) * -1, MIN_PRICE_PRECISION),
		})

	const cancelHandler =
		(id: string, symbol = "") =>
		async () => {
			try {
				await ExchangeService.cancelOrder(id, "spot", symbol)
				messageSuccess(formatMessage(historyMessages.order_was_cancelled))
				refetch?.()
			} catch (err) {
				errorHandler(err, false)
			}
		}

	return [
		{
			key: "date",
			//TODO: translate
			caption: "Date",
			width: 1,
			render: ({ date }) => formatDate(date * 1000),
		},
		{
			key: "pair",
			caption: formatMessage(historyMessages.active_orders_pair),
			render: ({ pair }) => `${pair?.baseCoin}/${pair?.quoteCoin}`,
		},
		{
			key: "type",
			caption: formatMessage(historyMessages.order_type),
			render: ({ type }) => capitalizeFirstLetter(type, true),
		},
		{
			key: "side",
			//TODO: translate
			caption: "Direction",
			render: ({ side }) => (
				<Badge color={directionColor[side as EOrderSide]} alpha>
					{formatMessage(
						side === EOrderSide.BUY
							? historyMessages.orders_table_type_2
							: historyMessages.orders_table_type_1,
					)}
				</Badge>
			),
		},
		{
			key: "price",
			caption: formatMessage(historyMessages.order_price),
			render: ({ price, pair }) =>
				`${getFormatedNumber(price, pair?.lotSizeFilter?.basePrecision)} ${pair?.baseCoin}`,
		},
		{
			key: "amount_original",
			caption: formatMessage(historyMessages.active_orders_amount),
			render: ({ amount_original, pair }) =>
				`${getFormatedNumber(amount_original, pair?.lotSizeFilter?.quotePrecision)} ${
					pair?.quoteCoin
				}`,
		},
		{
			key: "filled_percent",
			caption: formatMessage(historyMessages.active_orders_filled),
			render: ({ filled_percent }) =>
				`${formatNumber(filled_percent || 0, {
					minimumFractionDigits: PERCENT_PRECISION,
					maximumFractionDigits: PERCENT_PRECISION,
				})}%`,
		},
		{
			key: "quote_amount",
			caption: formatMessage(historyMessages.active_orders_total),
			render: ({ quote_amount, pair }) =>
				`${getFormatedNumber(quote_amount, pair?.lotSizeFilter?.quotePrecision)} ${
					pair?.quoteCoin
				}`,
		},
		{
			key: "amount_unfilled",
			caption: formatMessage(historyMessages.unfilled_qty),
			render: ({ amount_unfilled, pair }) =>
				`${getFormatedNumber(amount_unfilled, pair?.lotSizeFilter?.quotePrecision)} ${
					pair?.quoteCoin
				}`,
		},
		{
			key: "id",
			caption: formatMessage(historyMessages.order_id),
		},
		{
			key: "actions",
			caption: formatMessage(historyMessages.orders_table_action),
			width: 1,
			actions: ({ id, pair }) => [
				{
					icon: "ai-cancel_mini",
					onClick: cancelHandler(id, pair?.symbol),
				},
			],
		},
	]
}

export const useFilters = (): TTableFilters<IOrder> => {
	const { formatMessage } = useIntl()
	const {
		bybit: { loadPairs, spotPairs },
	} = useMst()

	useEffect(() => {
		!spotPairs?.length && loadPairs()
	}, [spotPairs])

	return [
		{
			key: "symbol",
			type: ETableFilterType.SELECT,
			items: spotPairs?.map(({ baseCoin, quoteCoin, symbol }) => ({
				value: symbol,
				label: `${baseCoin}/${quoteCoin}`,
			})),
			//TODO: translate
			caption: "Quote Assets",
		},
		// {
		// 	key: "type",
		// 	type: ETableFilterType.SELECT,
		// 	items: [
		// 		{ value: EOrderType.LIMIT, label: formatMessage(exchangeMessages.order_type_limit) },
		// 		{ value: EOrderType.MARKET, label: formatMessage(exchangeMessages.order_type_market) },
		// 	],
		// 	caption: formatMessage(historyMessages.order_type),
		// },
		// {
		// 	key: "side",
		// 	type: ETableFilterType.SELECT,
		// 	items: [
		// 		{ value: EOrderSide.BUY, label: formatMessage(historyMessages.orders_table_type_2) },
		// 		{ value: EOrderSide.SELL, label: formatMessage(historyMessages.orders_table_type_1) },
		// 	],
		// 	caption: formatMessage(historyMessages.transfers_direction),
		// },
	]
}
