import { makeAutoObservable } from "mobx"

import { TerminalStore } from "./Terminal"
import AccountStore from "./Account"

class RootStore {
	terminal
	account

	constructor() {
		this.terminal = new TerminalStore()
		this.account = new AccountStore()

		makeAutoObservable(this)
	}
}

export const rootStore = new RootStore()
