import { makeAutoObservable, runInAction } from "mobx"

import { TTicker } from "types/ticker"
import ExchangeService from "services/ExchangeService"
import { handleError } from "utils/decorators"
import { FAVORITE_TICKERS_CACHE_KEY } from "utils/cacheKeys"
import cache from "helpers/cache"

export default class TickersStore {
	isLoaded = false
	list: TTicker[] = []
	favorites
	current?: Partial<TTicker>

	constructor() {
		this.favorites = new Set(cache.getItem<string[]>(FAVORITE_TICKERS_CACHE_KEY, []))

		makeAutoObservable(this)
	}

	clear() {
		this.list = []
		this.current = undefined
		this.isLoaded = false
	}

	@handleError
	async load(current?: string) {
		const response = await ExchangeService.getTickers("spot")
		const list = response.filter(({ base_coin, quote_coin }) => base_coin && quote_coin)

		runInAction(() => {
			if (current) {
				const ticker = list.find(({ symbol }) => symbol === current)
				if (ticker) this.current = ticker
			}
			this.list = list
			this.isLoaded = true
		})
	}

	setCurrent(ticker: Partial<TTicker>) {
		this.current = ticker
		this.list = this.list.map(item => {
			if (item.symbol === ticker.symbol) return { ...item, ...ticker }
			return item
		})
	}

	addToFavorites(coin: string) {
		const newFavorites = new Set(this.favorites)
		newFavorites.add(coin)
		this.favorites = newFavorites
		cache.setItem(FAVORITE_TICKERS_CACHE_KEY, newFavorites)
	}

	removeFromFavorites(coin: string) {
		const newFavorites = new Set(this.favorites)
		newFavorites.delete(coin)
		this.favorites = newFavorites
		cache.setItem(FAVORITE_TICKERS_CACHE_KEY, newFavorites)
	}
}
