import { makeAutoObservable, reaction, runInAction } from "mobx"

import { EBybitWalletType, TBalanceCoin } from "types/account"
import AccountService from "services/AccountService"
import { handleError } from "utils/decorators"

export default class BalanceStore {
	private type: EBybitWalletType

	isLoaded = false
	list: TBalanceCoin[] = []
	amount = 0

	constructor(type: EBybitWalletType) {
		this.type = type

		makeAutoObservable(this)

		reaction(
			() => this.list,
			list => {
				this.amount = list.reduce((sum, { converted }) => sum + converted.USD, 0)
			},
		)
	}

	clear() {
		this.list = []
		this.isLoaded = false
	}

	@handleError
	async load() {
		const response = await AccountService.getBalances({
			account_type: this.type,
		})
		runInAction(() => {
			this.list = response
			this.isLoaded = true
		})
	}

	patch(items: TBalanceCoin[]) {
		const codes = items.map(({ code }) => code)
		this.list = this.list.map(item => {
			if (!codes.includes(item.code)) return item
			return items.find(({ code }) => code === item.code) as TBalanceCoin
		})
	}
}
