/* eslint-disable react/button-has-type */
import React, {
	ButtonHTMLAttributes,
	CSSProperties,
	MouseEventHandler,
	PropsWithChildren,
	ReactNode,
	useState,
} from "react"
import { NavLink } from "react-router-dom"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"
import { rem } from "utils/styles"
import { TNavigationParams, useLocalePath } from "hooks/useLocaleNavigate"

import AlphaIcon from "components/redesigned/AlphaIcon"

import { ReactComponent as Loader } from "./loader.svg"
import styles from "./button.module.scss"

export type TButtonSize = "full" | "middle" | "mini"
export type TButtonKing = "primary" | "secondary" | "outlined" | "clear"

const sizeClasses: Record<TButtonSize, string> = {
	full: styles.full,
	middle: styles.middle,
	mini: styles.mini,
}

const kindClasses: Record<TButtonKing, string> = {
	primary: styles.primary,
	secondary: styles.secondary,
	outlined: styles.outlined,
	clear: styles.clear,
}

interface TProps {
	caption?: string | number
	type?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
	icon?: ReactNode | TAlphaIcon | string
	iconSize?: number
	size?: TButtonSize
	kind?: TButtonKing
	title?: string
	disabled?: boolean
	loading?: boolean | "auto"
	style?: CSSProperties
	className?: string
	to?: string | TNavigationParams
	onClick?: MouseEventHandler<HTMLButtonElement>
}

const Button: React.FC<PropsWithChildren<TProps>> = ({
	caption,
	type = "button",
	icon,
	iconSize,
	size = "full",
	kind = "primary",
	title,
	disabled,
	loading,
	children,
	style,
	className,
	to,
	onClick,
}) => {
	const getLocalePath = useLocalePath()

	const [processing, setProcessing] = useState<boolean>(false)

	const handleClick: MouseEventHandler<HTMLButtonElement> = async event => {
		if (!onClick) return
		try {
			setProcessing(true)
			await onClick(event)
		} finally {
			setProcessing(false)
		}
	}

	const loader = loading === true || (loading === "auto" && processing)

	const commonProps = {
		title,
		className: cn(
			styles.button,
			sizeClasses[size],
			kindClasses[kind],
			{ [styles.disabled]: disabled, [styles.loading]: loader },
			className,
		),
		style:
			!style && !iconSize
				? undefined
				: {
						"--button-icon-size": iconSize ? rem(iconSize) : undefined,
						...style,
				  },
	}

	const content = (
		<>
			{typeof icon === "string" ? (
				icon.startsWith("ai") ? (
					<AlphaIcon name={icon as TAlphaIcon} />
				) : (
					<img src={icon} alt={`${caption || ""}`} />
				)
			) : (
				icon
			)}
			{!!caption && <span>{caption}</span>}
			{children}
			{loader && <Loader />}
		</>
	)

	if (to)
		return (
			<NavLink to={getLocalePath(to)} {...commonProps}>
				{content}
			</NavLink>
		)

	return (
		<button type={type} disabled={disabled || loader} onClick={handleClick} {...commonProps}>
			{content}
		</button>
	)
}

export default Button
