/* eslint-disable no-unused-expressions,jsx-a11y/control-has-associated-label */
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { EBybitWalletType } from "types/account"
import { routes } from "constants/routing"
import { STARS_STR } from "constants/wallet"
import commonMessages from "messages/common"
import exchangeMessages from "messages/exchange"
import financeMessages from "messages/finance"
import { useMobxStore } from "store"
import useLocationSearch from "hooks/useLocationSearch"
import useModal from "hooks/useModal"

import RedesignedLayout from "layouts/RedesignedLayout"
import LoadingSpinner from "components/UI/LoadingSpinner"
import Table from "components/redesigned/Table"
import Button from "components/redesigned/Button"
import AlphaIcon from "components/redesigned/AlphaIcon"

import { useColumns, useFilters } from "./wallets.hooks"
import Transfer from "./Transfer"

import styles from "./wallets.module.scss"

const Wallets = () => {
	const { formatNumber, formatMessage } = useIntl()
	const { type } = useLocationSearch<{ type: EBybitWalletType }>({ type: EBybitWalletType.FUND })
	const {
		account: { wallet },
	} = useMobxStore()

	const [openTransfer] = useModal(Transfer, {
		title: formatMessage(exchangeMessages.action_margin_transfer),
		width: 500,
		closeBack: false,
	})

	const balances = {
		[EBybitWalletType.FUND]: wallet.fund,
		[EBybitWalletType.UNIFIED]: wallet.unified,
	}

	const titles = {
		//TODO: translate
		[EBybitWalletType.FUND]: "Funding Account",
		[EBybitWalletType.UNIFIED]: "Unified Trading",
	}

	const balance = balances[type]
	const title = titles[type]

	const handleOpenTransfer = (coin?: string) => openTransfer({ from: type, coin })

	const columns = useColumns({ type, onTransferClick: handleOpenTransfer })
	const filters = useFilters()

	const ordered = useMemo(
		() =>
			[...balance.list].sort((a, b) => {
				if (!a.balance !== !b.balance) return Math.abs(b.balance) - Math.abs(a.balance)
				return a.code.localeCompare(b.code)
			}),
		[balance.list],
	)

	return (
		<RedesignedLayout className={styles.wallets}>
			<div className={styles.heading}>
				<h2>{title}</h2>
				<button
					type="button"
					title={formatMessage(
						wallet.isVisible ? financeMessages.hide_balance : financeMessages.show_balance,
					)}
					onClick={() => wallet.toggleVisibility()}
				>
					<AlphaIcon
						name={wallet.isVisible ? "ai-eye_disabled" : "ai-eye"}
						size={20}
						color="--redesigned-color-text-primary"
					/>
				</button>
			</div>
			{!balance.isLoaded ? (
				<LoadingSpinner />
			) : (
				<>
					<div className={styles.info}>
						<div>
							<span className={styles.title}>Total Equity</span>
							<span className={styles.amount}>
								$
								{wallet.isVisible
									? formatNumber(balance.amount, {
											useGrouping: true,
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
									  })
									: STARS_STR}
							</span>
						</div>
						<div>
							{type === EBybitWalletType.FUND && (
								<>
									<Button
										caption={formatMessage(commonMessages.deposit)}
										icon="ai-credit-card-upload"
										size="mini"
										to={routes.profile.createDeposit}
									/>
									<Button
										caption={formatMessage(commonMessages.withdraw)}
										icon="ai-credit-card-download"
										size="mini"
										kind="outlined"
										to={routes.profile.createWithdraw}
									/>
								</>
							)}
							<Button
								caption={formatMessage(commonMessages.transfer)}
								icon="ai-change_arrows"
								size="mini"
								kind="outlined"
								onClick={() => handleOpenTransfer()}
							/>
						</div>
					</div>
					{/*TODO: translate*/}
					<h2>Assets</h2>
					<Table
						id="code"
						columns={columns}
						filters={filters}
						data={ordered}
						footer="pagination"
						defLimit={100}
						firstSticky
						lastSticky
					/>
				</>
			)}
		</RedesignedLayout>
	)
}

export default observer(Wallets)
