/* eslint-disable no-unused-expressions,jsx-a11y/control-has-associated-label */
import React, { MouseEventHandler } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cn from "classnames"

import { EBybitWalletType } from "types/account"
import { routes } from "constants/routing"
import { STARS_STR } from "constants/wallet"
import commonMessages from "messages/common"
import exchangeMessages from "messages/exchange"
import financeMessages from "messages/finance"
import { useMobxStore } from "store"
import { useMst } from "models/Root"
import useModal from "hooks/useModal"
import useAccount from "hooks/useAccount"
import useCopyClick from "hooks/useCopyClick"
import { MAX_PRICE_PRECISION, USD_PRECISION } from "utils/constants"

import RedesignedLayout from "layouts/RedesignedLayout"
import LoadingSpinner from "components/UI/LoadingSpinner"
import Button from "components/redesigned/Button"

import IconDatabase from "assets/icons/menu/database.svg"
import IconPercent from "assets/icons/menu/percent.svg"
import IconBot from "assets/icons/bot.svg"

import Transfer from "../Wallets/Transfer"

import styles from "./walletOverview.module.scss"

const WalletOverview = () => {
	const { formatNumber, formatMessage } = useIntl()
	const {
		account: { aggregatedBalance },
	} = useMst()
	const {
		account: { wallet },
	} = useMobxStore()
	const { character, name, uid } = useAccount()
	const copy = useCopyClick()

	const [openTransfer] = useModal(Transfer, {
		title: formatMessage(exchangeMessages.action_margin_transfer),
		width: 500,
		closeBack: false,
	})
	const handleOpenTransferFund: MouseEventHandler = event => {
		event.preventDefault()
		openTransfer({ from: EBybitWalletType.FUND })
	}
	const handleOpenTransferUnified: MouseEventHandler = event => {
		event.preventDefault()
		openTransfer({ from: EBybitWalletType.UNIFIED })
	}

	const handleCopyUidClick = () => uid && copy(uid)
	const handleVisibilityClick = () => wallet.toggleVisibility()

	const estimatedBalance = wallet.isVisible
		? formatNumber(wallet.fund.amount + wallet.unified.amount, {
				useGrouping: true,
				minimumFractionDigits: USD_PRECISION,
				maximumFractionDigits: USD_PRECISION,
		  })
		: STARS_STR

	const btcRate = (aggregatedBalance.BTC / aggregatedBalance.USDT) || 0

	const fundAmount = wallet.isVisible
		? formatNumber(wallet.fund.amount, {
				useGrouping: true,
				minimumFractionDigits: USD_PRECISION,
				maximumFractionDigits: USD_PRECISION,
		  })
		: STARS_STR

	const fundAmountBtc = wallet.isVisible
		? formatNumber(wallet.fund.amount * btcRate, {
				useGrouping: true,
				minimumFractionDigits: MAX_PRICE_PRECISION,
				maximumFractionDigits: MAX_PRICE_PRECISION,
		  })
		: STARS_STR

	const unifiedAmount = wallet.isVisible
		? formatNumber(wallet.unified.amount, {
				useGrouping: true,
				minimumFractionDigits: USD_PRECISION,
				maximumFractionDigits: USD_PRECISION,
		  })
		: STARS_STR

	const unifiedAmountBtc = wallet.isVisible
		? formatNumber(wallet.unified.amount * btcRate, {
				useGrouping: true,
				minimumFractionDigits: MAX_PRICE_PRECISION,
				maximumFractionDigits: MAX_PRICE_PRECISION,
		  })
		: STARS_STR

	return (
		<RedesignedLayout
			// TODO: translate
			title="Overview"
			helmet={{ title: "Overview" }}
			className={styles.walletOverview}
		>
			{!wallet.isLoaded ? (
				<LoadingSpinner />
			) : (
				<>
					<div className={styles.account}>
						<img src={character} alt="" className={styles.character} />
						{!!name && (
							<div className={styles.info}>
								<div>
									{/*TODO: translate*/}
									Welcome back,
								</div>
								<div className={styles.name}>
									<span>{name}</span>
								</div>
							</div>
						)}
						<div className={styles.infos}>
							{!!uid && (
								<div className={styles.info}>
									<div>
										{/*TODO: translate*/}
										User ID
									</div>
									<div>
										<span>{uid}</span>
										<Button
											size="mini"
											kind="clear"
											icon="ai-copy_new"
											iconSize={12}
											onClick={handleCopyUidClick}
										/>
									</div>
								</div>
							)}
							{/*<div className={styles.info}>*/}
							{/*	<div>*/}
							{/*		/!*TODO: translate*!/*/}
							{/*		VIP Level*/}
							{/*	</div>*/}
							{/*	<div>*/}
							{/*		<span>VIP 1</span>*/}
							{/*		<Button*/}
							{/*			size="mini"*/}
							{/*			kind="clear"*/}
							{/*			icon="ai-chevron_right"*/}
							{/*			iconSize={10}*/}
							{/*			onClick={handleCopyUidClick}*/}
							{/*		/>*/}
							{/*	</div>*/}
							{/*</div>*/}
							<div className={styles.info}>
								<div>
									{/*TODO: translate*/}
									User Type
								</div>
								<div>
									<span>Personal</span>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.wallet}>
						<div className={styles.estimated}>
							<div>
								{/*TODO: translate*/}
								<span>Estimated Balance</span>
								<Button
									size="mini"
									kind="clear"
									icon={wallet.isVisible ? "ai-eye_disabled" : "ai-eye"}
									iconSize={16}
									title={formatMessage(
										wallet.isVisible ? financeMessages.hide_balance : financeMessages.show_balance,
									)}
									onClick={handleVisibilityClick}
								/>
							</div>
							<div className={styles.amount}>${estimatedBalance}</div>
						</div>
						<div className={styles.actions}>
							<Button
								caption={formatMessage(commonMessages.deposit)}
								icon="ai-credit-card-upload"
								size="mini"
								to={routes.profile.createDeposit}
							/>
							<Button
								caption={formatMessage(commonMessages.withdraw)}
								icon="ai-credit-card-download"
								size="mini"
								kind="outlined"
								to={routes.profile.createWithdraw}
							/>
							<Button
								caption={formatMessage(commonMessages.transfer)}
								icon="ai-change_arrows"
								size="mini"
								kind="outlined"
								onClick={handleOpenTransferFund}
							/>
						</div>
					</div>
					{/*TODO: translate*/}
					<h2>Accounts</h2>
					{/*TODO: translate*/}
					<h4>My Assets</h4>
					<Button
						kind="secondary"
						className={styles.asset}
						to={{ path: routes.profile.wallets, search: { type: EBybitWalletType.FUND } }}
					>
						<div className={styles.caption}>
							<img
								src={IconDatabase}
								// TODO: translate
								alt="Funding"
							/>
							{/*TODO: translate*/}
							<span>Funding</span>
						</div>
						<div className={styles.balance}>
							<div>${fundAmount}</div>
							<div>≈ {fundAmountBtc} BTC</div>
						</div>
						<div className={styles.actions}>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-credit-card-upload"
								title={formatMessage(commonMessages.deposit)}
								to={routes.profile.createDeposit}
							/>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-credit-card-download"
								title={formatMessage(commonMessages.withdraw)}
								to={routes.profile.createWithdraw}
							/>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-change_arrows"
								title={formatMessage(commonMessages.transfer)}
								onClick={handleOpenTransferFund}
							/>
						</div>
					</Button>
					<Button
						kind="secondary"
						className={styles.asset}
						to={{ path: routes.profile.wallets, search: { type: EBybitWalletType.UNIFIED } }}
					>
						<div className={styles.caption}>
							<img
								src={IconPercent}
								// TODO: translate
								alt="Unified Trading"
							/>
							{/*TODO: translate*/}
							<span>Unified Trading</span>
						</div>
						<div className={styles.balance}>
							<div>${unifiedAmount}</div>
							<div>≈ {unifiedAmountBtc} BTC</div>
						</div>
						<div className={styles.actions}>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-change_arrows"
								title={formatMessage(commonMessages.transfer)}
								onClick={handleOpenTransferUnified}
							/>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-trade-candle-01"
								title={formatMessage(commonMessages.trade)}
								to={routes.trade.root}
							/>
						</div>
					</Button>
					{/*TODO: translate*/}
					<h4>Invested Products</h4>
					<Button
						kind="secondary"
						disabled
						className={styles.asset}
						to={{ path: routes.profile.wallets, search: { type: EBybitWalletType.UNIFIED } }}
					>
						<div className={styles.caption}>
							<img
								src={IconBot}
								// TODO: translate
								alt="Trading Bot"
							/>
							{/*TODO: translate*/}
							<span>Trading Bot</span>
						</div>
						<div className={styles.balance}>
							<div>$0.00</div>
							<div>≈ 0.00 BTC</div>
						</div>
						<div className={styles.actions}>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-credit-card-upload"
								title={formatMessage(commonMessages.deposit)}
								disabled
							/>
							<Button
								size="mini"
								kind="outlined"
								icon="ai-trade-candle-01"
								title={formatMessage(commonMessages.trade)}
								disabled
							/>
						</div>
						<div className={styles.soon}>
							{/*TODO: translate*/}
							<span>Coming soon</span>
						</div>
					</Button>
				</>
			)}
		</RedesignedLayout>
	)
}

export default observer(WalletOverview)
