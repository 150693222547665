/* eslint-disable prefer-const,no-unused-expressions */
import { useNavigate } from "react-router-dom"

import { useMst } from "models/Root"

export type TNavigationParams = {
	path?: string
	search?: Record<string, string>
}

export const useLocalePath = () => {
	const {
		global: { locale },
	} = useMst()
	const getLocalePath = (to: string | TNavigationParams) => {
		if (typeof to === "string") return `/${locale}${to}`
		const { path, search } = to
		if (!path && !search) return ""
		const nextPath = path ? `/${locale}${path}` : window.location.pathname
		let query = new URLSearchParams(search).toString()
		if (query.length) query = `?${query}`
		return `${nextPath}${query}`
	}
	return getLocalePath
}

const useLocaleNavigate = () => {
	const navigate = useNavigate()
	const getLocalePath = useLocalePath()
	return (to: string | TNavigationParams) => {
		const path = getLocalePath(to)
		path && navigate(path)
	}
}

export default useLocaleNavigate
