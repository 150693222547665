/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from "react"
import { useIntl } from "react-intl"

import { EOrderSide } from "types/orders"
import { TBybitInstrumentSpot } from "types/bybit"
import { useMst } from "models/Root"
import { ITrade } from "types/history"
import historyMessages from "messages/history"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION } from "utils/constants"
import { formatDate } from "utils/formatDate"

import { ETableFilterType, TTableColumns, TTableFilters } from "components/redesigned/Table"
import Badge, { BadgeColorEnum } from "components/UI/Badge"

const directionColor: Record<EOrderSide, BadgeColorEnum> = {
	[EOrderSide.BUY]: BadgeColorEnum.GREEN,
	[EOrderSide.SELL]: BadgeColorEnum.RED,
}

export const useColumns = (): TTableColumns<ITrade> => {
	const { formatNumber, formatMessage } = useIntl()
	const {
		bybit: { spotPairs, loadPairs },
	} = useMst()

	useEffect(() => {
		!spotPairs?.length && loadPairs()
	}, [spotPairs])

	const getFormatedNumber = (
		value: number | null,
		decimal: number | string = MAX_PRICE_PRECISION,
	) =>
		formatNumber(value || 0, {
			minimumFractionDigits: MIN_PRICE_PRECISION,
			maximumFractionDigits: Math.max(Math.log10(+decimal) * -1, MIN_PRICE_PRECISION),
		})

	const pairsRef = useRef(new Map<string, TBybitInstrumentSpot | undefined>())
	useEffect(() => pairsRef.current?.clear(), [spotPairs.length])
	const getPair = (symbol: string) => {
		if (pairsRef.current.has(symbol)) return pairsRef.current.get(symbol)
		const pair = spotPairs.find(item => item.symbol === symbol)
		pairsRef.current.set(symbol, pair)
		return pair
	}

	return [
		{
			key: "date",
			//TODO: translate
			caption: "Date",
			width: 1,
			render: ({ date }) => (!date ? "–" : formatDate(date * 1000)),
		},
		{
			key: "pair_id",
			caption: formatMessage(historyMessages.active_orders_pair),
			render: ({ pair_id }) => {
				const pair = getPair(pair_id)
				return !pair?.baseCoin ? "" : `${pair?.baseCoin}/${pair?.quoteCoin}`
			},
		},
		{
			key: "side",
			//TODO: translate
			caption: "Direction",
			render: ({ side }) => (
				<Badge color={directionColor[side as EOrderSide]} alpha>
					{formatMessage(
						side === EOrderSide.BUY
							? historyMessages.orders_table_type_2
							: historyMessages.orders_table_type_1,
					)}
				</Badge>
			),
		},
		{
			key: "price",
			caption: formatMessage(historyMessages.active_orders_trading_price),
			render: ({ price, pair_id }) => {
				const pair = getPair(pair_id)
				return `${getFormatedNumber(price, pair?.lotSizeFilter?.basePrecision)} ${
					pair?.baseCoin || ""
				}`
			},
		},
		{
			key: "amount1",
			caption: formatMessage(historyMessages.active_orders_amount),
			render: ({ amount1, pair_id }) => {
				const pair = getPair(pair_id)
				return `${getFormatedNumber(amount1, pair?.lotSizeFilter?.basePrecision)} ${
					pair?.baseCoin || ""
				}`
			},
		},
		{
			key: "amount2",
			caption: formatMessage(historyMessages.active_orders_total),
			render: ({ amount2, pair_id }) => {
				const pair = getPair(pair_id)
				return `${getFormatedNumber(amount2, pair?.lotSizeFilter?.quotePrecision)} ${
					pair?.quoteCoin || ""
				}`
			},
		},
		{
			key: "fee_amount",
			caption: formatMessage(historyMessages.active_orders_transaction_fee),
			render: ({ fee_amount }) =>
				!fee_amount
					? "–"
					: formatNumber(+fee_amount, { maximumFractionDigits: MAX_PRICE_PRECISION }),
		},
	]
}

export const useFilters = (): TTableFilters<ITrade> => {
	const { formatMessage } = useIntl()
	const {
		bybit: { loadPairs, spotPairs },
	} = useMst()

	useEffect(() => {
		!spotPairs?.length && loadPairs()
	}, [spotPairs])

	return [
		{
			key: "pair_id",
			type: ETableFilterType.SELECT,
			items: spotPairs?.map(({ baseCoin, quoteCoin, symbol }) => ({
				value: symbol,
				label: `${baseCoin}/${quoteCoin}`,
			})),
			//TODO: translate
			caption: "Quote Assets",
		},
		// {
		// 	key: "type",
		// 	type: ETableFilterType.SELECT,
		// 	items: [
		// 		{ value: EOrderType.LIMIT, label: formatMessage(exchangeMessages.order_type_limit) },
		// 		{ value: EOrderType.MARKET, label: formatMessage(exchangeMessages.order_type_market) },
		// 	],
		// 	caption: formatMessage(historyMessages.order_type),
		// },
		// {
		// 	key: "side",
		// 	type: ETableFilterType.SELECT,
		// 	items: [
		// 		{ value: EOrderSide.BUY, label: formatMessage(historyMessages.orders_table_type_2) },
		// 		{ value: EOrderSide.SELL, label: formatMessage(historyMessages.orders_table_type_1) },
		// 	],
		// 	caption: formatMessage(historyMessages.transfers_direction),
		// },
	]
}
