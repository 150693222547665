import ApiClient from "helpers/ApiClient"
import { IHistoryOrder } from "models/History"
import { IMarginCurrencyStatus } from "models/Terminal"
import { ITicker } from "models/Ticker"
import {
	ICreateOrderBody,
	IGetExchangeParams,
	IGetMarginCurrencyStatusParams,
	IMarginBorrowBody,
	IMarginTransferBody,
	TGetMarginStatResponse,
	TGetRepayOptionsResponse,
	TPostMarginStatResponse,
} from "types/exchange"
import { TTicker } from "types/ticker"
import { IPaginationRes } from "types/general"
import { ILoanConditions } from "types/history"
import { queryVars } from "constants/query"

const ExchangeService = {
	getTickers: (category?: string, symbol?: string) =>
		ApiClient.get<TTicker[]>("web/finance/tickers", { category, symbol }),
	getExchange: (params: IGetExchangeParams): Promise<Record<string, unknown>> =>
		ApiClient.get("web/finance/exchange", params),
	getChartData: (pair: string, resolution: string, params: any): Promise<any> =>
		ApiClient.get(`charts/${pair}/${resolution}/chart/`, params),
	cancelOrder: (orderId: string, category?: string, pair?: string): Promise<any> =>
		ApiClient.post("web/finance/cancel-order", { order_id: orderId, pair, category }),
	cancelAllOrders: (params?: { [queryVars.pair]?: string }): Promise<any> =>
		ApiClient.post("web/finance/cancel-open-orders", params),
	createOrder: (body: ICreateOrderBody): Promise<IHistoryOrder> =>
		ApiClient.post("web/finance/create-order", body),
	getCurrencyStatus: (params: IGetMarginCurrencyStatusParams): Promise<IMarginCurrencyStatus> =>
		ApiClient.get("web/margin/currency-status", params),
	updateFavoritePair: (symbol: string, isFavorite: boolean): Promise<void> =>
		ApiClient.post("web/finance/favorite-pair", {
			symbol,
			[queryVars.is_favorite]: isFavorite,
		}),
	marginBorrow: (body: IMarginBorrowBody): Promise<void> =>
		ApiClient.post("web/margin/borrow", body),
	marginRepay: (body: IMarginBorrowBody): Promise<void> => ApiClient.post("web/margin/repay", body),
	marginTransfer: (body: IMarginTransferBody): Promise<void> =>
		ApiClient.post("web/margin/transfer", body),
	acceptMarginTerms: (): Promise<void> =>
		ApiClient.post("web/margin/accept-margin-terms", { accept_terms: true }),
	getLoanConditions: (): Promise<IPaginationRes<ILoanConditions>> =>
		ApiClient.get("web/margin/loan-conditions"),

	getLastMarginLeverage: () => ApiClient.get<TGetMarginStatResponse>("web/margin/margin-state"),
	submitMarginLeverage: (leverage: number) =>
		ApiClient.post<TPostMarginStatResponse>("web/margin/change-margin-leverage", { leverage }),

	getRepayOptions: (asset: string) =>
		ApiClient.post<TGetRepayOptionsResponse>("web/margin/repay-options", { asset }),
	submitRepayLiability: (asset: string, repayment_assets: string[]) =>
		ApiClient.post<TGetRepayOptionsResponse>("web/margin/repay-liability", {
			asset,
			repayment_assets,
		}),
}

export default ExchangeService
