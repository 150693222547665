import React, { useRef } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { ITrade } from "types/history";
import HistoryService from "services/HistoryService"
import { getPageTitle } from "helpers/global"

import RedesignedLayout from "layouts/RedesignedLayout"
import Table, { TTableOnFetch, TTableRef } from "components/redesigned/Table"

import { useColumns, useFilters } from "./tradesHistory.hooks"
import styles from "./tradesHistory.module.scss"

const TradesHistory: React.FC = () => {
	const { formatMessage } = useIntl()

	const columns = useColumns()
	const filters = useFilters()

	const handleFetch: TTableOnFetch<ITrade> = async ({ cursor, limit, filters }) => {
		const { results: data, cursor: next } = await HistoryService.getTrades({
			category: "spot",
			symbol: filters.symbol,
			limit,
			cursor,
		})
		return { data, next }
	}

	return (
		<RedesignedLayout
			//TODO: translate
			title="Trade History"
			//TODO: translate
			helmet={{ title: getPageTitle("Trade History") }}
			className={styles.closedOrders}
		>
			<div className={styles.table}>
				<Table<ITrade>
					columns={columns}
					filters={filters}
					//TODO: translate
					empty="No trades to show."
					lazyLoad="cursor"
					resetFilters
					defFetching
					footer="pagination"
					onFetch={handleFetch}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(TradesHistory)
