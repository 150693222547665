import { useSearchParams } from "react-router-dom"

export default function useLocationSearch<
	Params extends Record<string, string> = Record<string, string>,
>(defaults: Record<string, string> = {}) {
	const [params] = useSearchParams()
	return {
		...defaults,
		...Object.fromEntries(params.entries()),
	} as Params
}
