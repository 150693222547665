import React, { createElement, JSX, PropsWithChildren } from "react"
import cn from "classnames"

import { TAlphaIcon } from "types/alphaCodes"

import styles from "./field.module.scss"
import AlphaIcon from "../AlphaIcon"

type TFieldProps<Component> = {
	fLabel?: string | React.ReactNode
	fLabelIcon?: TAlphaIcon | React.ReactNode
	fSubline?: string | JSX.Element
	fError?: string | boolean
	fRequired?: boolean
	fAction?: string
	fActionChevron?: boolean
	fClassName?: string
	fOnActionClick?: () => void
	fComponent?: Component
}

function Field<
	Component extends React.FC<any>,
	Props = Component extends React.FC<infer P> ? P : never,
>({
	fLabel,
	fLabelIcon,
	fSubline,
	fError,
	fRequired,
	fAction,
	fActionChevron,
	fClassName,
	fOnActionClick,
	fComponent,
	children,
	...props
}: PropsWithChildren<TFieldProps<Component>> & Props) {
	const isLabel = !!fLabel
	const isAction = !!fAction?.length

	return (
		<div
			className={cn(
				styles.field,
				{ [styles.error]: fError === true || (typeof fError === "string" && !!fError?.length) },
				fClassName,
			)}
		>
			{(isLabel || isAction) && (
				<div className={styles.header}>
					{typeof fLabelIcon === "string" ? (
						<AlphaIcon
							name={fLabelIcon as TAlphaIcon}
							size={20}
							color="--redesigned-color-text-primary"
						/>
					) : (
						fLabelIcon
					)}
					{isLabel && (
						<span>
							{fLabel}
							{fRequired && <i>*</i>}
						</span>
					)}
					{isAction && (
						<button type="button" onClick={fOnActionClick}>
							<span>{fAction}</span>
							{fActionChevron && <i className="ai ai-chevron_right" />}
						</button>
					)}
				</div>
			)}
			{fComponent && createElement(fComponent as never, props as never)}
			{children}
			{typeof fSubline === "string" && fSubline.length && typeof fError !== "string" && (
				<div className={styles.subline}>{fSubline}</div>
			)}
			{typeof fError === "string" && !!fError?.length && (
				<div className={styles.subline}>{fError}</div>
			)}
			{!!fSubline && typeof fSubline !== "string" && fSubline}
		</div>
	)
}

export default Field
