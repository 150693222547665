import { useMst } from "models/Root"

import ImgHamster from "assets/images/characters/hamster.png"
// import ImgBull from "assets/images/characters/bull.png"
// import ImgBear from "assets/images/characters/bear.png"
// import ImgShark from "assets/images/characters/shark.png"
// import ImgOwl from "assets/images/characters/owl.png"
// import ImgLion from "assets/images/characters/lion.png"
// import ImgDragon from "assets/images/characters/dragon.png"
// import ImgWhale from "assets/images/characters/whale.png"

const useAccount = () => {
	const {
		global,
		account: { profileStatus },
	} = useMst()

	return {
		isAuthenticated: global.isAuthenticated,
		character: ImgHamster,
		...profileStatus,
	}
}

export default useAccount
