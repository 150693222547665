/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import cn from "classnames"

import { EOrderCloseStatus, EOrderOpenStatus, EOrderSide, TOrderStatus } from "types/orders"
import { useMst } from "models/Root"
import { IOrder } from "models/History"
import historyMessages from "messages/history"
import { MAX_PRICE_PRECISION, MIN_PRICE_PRECISION, PERCENT_PRECISION } from "utils/constants"
import { formatDate } from "utils/formatDate"
import { capitalizeFirstLetter } from "utils/reportUtils"
import { splitWords } from "utils/format"

import { ETableFilterType, TTableColumns, TTableFilters } from "components/redesigned/Table"
import Badge, { BadgeColorEnum } from "components/UI/Badge"

import styles from "./closedOrders.module.scss"

const directionColor: Record<EOrderSide, BadgeColorEnum> = {
	[EOrderSide.BUY]: BadgeColorEnum.GREEN,
	[EOrderSide.SELL]: BadgeColorEnum.RED,
}

const statusColor: Record<TOrderStatus, BadgeColorEnum> = {
	[EOrderOpenStatus.NEW]: BadgeColorEnum.BLUE,
	[EOrderOpenStatus.PARTIALLY_FILLED]: BadgeColorEnum.ORANGE,
	[EOrderOpenStatus.UNTRIGGERED]: BadgeColorEnum.ORANGE,
	[EOrderCloseStatus.CANCELLED]: BadgeColorEnum.GREY,
	[EOrderCloseStatus.PARTIALLY_FILLED_CANCELED]: BadgeColorEnum.GREY,
	[EOrderCloseStatus.REJECTED]: BadgeColorEnum.RED,
	[EOrderCloseStatus.FILLED]: BadgeColorEnum.GREEN,
	[EOrderCloseStatus.TRIGGERED]: BadgeColorEnum.ORANGE,
	[EOrderCloseStatus.DEACTIVATED]: BadgeColorEnum.GREY,
}

export const useColumns = (): TTableColumns<IOrder> => {
	const { formatNumber, formatMessage } = useIntl()

	const getFormatedNumber = (value: number | null, decimal: number = MAX_PRICE_PRECISION) =>
		formatNumber(value || 0, {
			minimumFractionDigits: MIN_PRICE_PRECISION,
			maximumFractionDigits: Math.max(Math.log10(decimal) * -1, MIN_PRICE_PRECISION),
		})

	return [
		{
			key: "date",
			//TODO: translate
			caption: "Date",
			width: 1,
			render: ({ date }) => formatDate(date * 1000),
		},
		{
			key: "pair",
			caption: formatMessage(historyMessages.active_orders_pair),
			render: ({ pair }) => `${pair?.baseCoin}/${pair?.quoteCoin}`,
		},
		{
			key: "type",
			caption: formatMessage(historyMessages.order_type),
			render: ({ type }) => capitalizeFirstLetter(type, true),
		},
		{
			key: "side",
			//TODO: translate
			caption: "Direction",
			render: ({ side }) => (
				<Badge color={directionColor[side as EOrderSide]} alpha>
					{formatMessage(
						side === EOrderSide.BUY
							? historyMessages.orders_table_type_2
							: historyMessages.orders_table_type_1,
					)}
				</Badge>
			),
		},
		{
			key: "price",
			caption: formatMessage(historyMessages.active_orders_price),
			render: ({ price, pair }) =>
				!price
					? "–"
					: `${getFormatedNumber(price, pair?.lotSizeFilter?.quotePrecision)} ${pair?.quoteCoin}`,
		},
		{
			key: "price_avg",
			caption: `${formatMessage(historyMessages.active_orders_price)} AVG`,
			render: ({ price_avg, pair }) =>
				!price_avg
					? "–"
					: `${getFormatedNumber(price_avg, pair?.lotSizeFilter?.quotePrecision)} ${
							pair?.quoteCoin
					  }`,
		},
		{
			key: "filled_percent",
			caption: formatMessage(historyMessages.active_orders_filled),
			render: ({ filled_percent }) =>
				`${formatNumber(filled_percent || 0, {
					minimumFractionDigits: PERCENT_PRECISION,
					maximumFractionDigits: PERCENT_PRECISION,
				})}%`,
		},
		{
			key: "amount_original",
			caption: formatMessage(historyMessages.active_orders_amount),
			render: ({ amount_original, pair }) =>
				`${getFormatedNumber(amount_original, pair?.lotSizeFilter?.basePrecision)} ${
					pair?.baseCoin
				}`,
		},
		{
			key: "value_filled",
			caption: formatMessage(historyMessages.active_orders_total),
			render: ({ value_filled, pair }) =>
				`${getFormatedNumber(value_filled, pair?.lotSizeFilter?.quotePrecision)} ${
					pair?.quoteCoin
				}`,
		},
		{
			key: "status",
			caption: formatMessage(historyMessages.state),
			render: ({ status }) => (
				<Badge
					color={statusColor[status as TOrderStatus]}
					alpha
					className={cn({
						[styles.cancelled]: [
							EOrderCloseStatus.CANCELLED,
							EOrderCloseStatus.PARTIALLY_FILLED_CANCELED,
						].includes(status as EOrderCloseStatus),
					})}
				>
					{splitWords(status)}
				</Badge>
			),
		},
	]
}

export const useFilters = (): TTableFilters<IOrder> => {
	const { formatMessage } = useIntl()
	const {
		bybit: { loadPairs, spotPairs },
	} = useMst()

	useEffect(() => {
		!spotPairs?.length && loadPairs()
	}, [spotPairs])

	return [
		{
			key: "symbol",
			type: ETableFilterType.SELECT,
			items: spotPairs?.map(({ baseCoin, quoteCoin, symbol }) => ({
				value: symbol,
				label: `${baseCoin}/${quoteCoin}`,
			})),
			//TODO: translate
			caption: "Quote Assets",
		},
		// {
		// 	key: "type",
		// 	type: ETableFilterType.SELECT,
		// 	items: [
		// 		{ value: EOrderType.LIMIT, label: formatMessage(exchangeMessages.order_type_limit) },
		// 		{ value: EOrderType.MARKET, label: formatMessage(exchangeMessages.order_type_market) },
		// 	],
		// 	caption: formatMessage(historyMessages.order_type),
		// },
		// {
		// 	key: "side",
		// 	type: ETableFilterType.SELECT,
		// 	items: [
		// 		{ value: EOrderSide.BUY, label: formatMessage(historyMessages.orders_table_type_2) },
		// 		{ value: EOrderSide.SELL, label: formatMessage(historyMessages.orders_table_type_1) },
		// 	],
		// 	caption: formatMessage(historyMessages.transfers_direction),
		// },
	]
}
