import { makeAutoObservable, reaction } from "mobx"

import { EBybitWalletType } from "types/account"
import { FAVORITE_WALLETS_CACHE_KEY, WALLET_BALANCE_VISIBILITY_CACHE_KEY } from "utils/cacheKeys"
import cache from "helpers/cache"

import BalanceStore from "./balance"

export default class WalletStore {
	fund
	unified
	favorites
	isVisible

	constructor() {
		this.fund = new BalanceStore(EBybitWalletType.FUND)
		this.unified = new BalanceStore(EBybitWalletType.UNIFIED)

		this.favorites = new Set(cache.getItem<string[]>(FAVORITE_WALLETS_CACHE_KEY, []))
		this.isVisible = cache.getItem<boolean>(WALLET_BALANCE_VISIBILITY_CACHE_KEY, true)

		makeAutoObservable(this)

		reaction(
			() => this.isVisible,
			visible => cache.setItem<boolean>(WALLET_BALANCE_VISIBILITY_CACHE_KEY, visible),
		)
	}

	get isLoaded() {
		return this.fund.isLoaded && this.unified.isLoaded
	}

	async load() {
		await Promise.all([this.fund.load(), this.unified.load()])
	}

	clear() {
		this.fund.clear()
		this.unified.clear()
	}

	setVisibility(visible: boolean) {
		this.isVisible = visible
	}

	toggleVisibility() {
		this.isVisible = !this.isVisible
	}

	addToFavorites(coin: string) {
		const newFavorites = new Set(this.favorites)
		newFavorites.add(coin)
		this.favorites = newFavorites
		cache.setItem(FAVORITE_WALLETS_CACHE_KEY, newFavorites)
	}

	removeFromFavorites(coin: string) {
		const newFavorites = new Set(this.favorites)
		newFavorites.delete(coin)
		this.favorites = newFavorites
		cache.setItem(FAVORITE_WALLETS_CACHE_KEY, newFavorites)
	}
}
