import React, { createContext, useContext, FC, ReactNode } from "react"

import { rootStore as mobxRootStore } from "./RootStore"

const RootStoreContext = createContext(mobxRootStore)

export const RootStoreProvider: FC<{ children: ReactNode }> = ({ children }) => (
	<RootStoreContext.Provider value={mobxRootStore}>{children}</RootStoreContext.Provider>
)

export const useMobxStore = () => useContext(RootStoreContext)
