import React, { useRef } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { IOrder } from "models/History"
import HistoryService from "services/HistoryService"
import { getPageTitle } from "helpers/global"

import RedesignedLayout from "layouts/RedesignedLayout"
import Table, { TTableOnFetch, TTableRef } from "components/redesigned/Table"

import { useColumns, useFilters } from "./activeOrders.hooks"
import styles from "./activeOrders.module.scss"

const ActiveOrders: React.FC = () => {
	const { formatMessage } = useIntl()
	const tableRef = useRef<TTableRef>(null)

	const columns = useColumns(tableRef.current?.refetch)
	const filters = useFilters()

	const handleFetch: TTableOnFetch<IOrder> = async ({ cursor, limit, filters }) => {
		const { results: data, cursor: next } = await HistoryService.getOpenOrders({
			category: "spot",
			symbol: filters.symbol,
			limit,
			cursor,
		})
		return { data, next }
	}

	return (
		<RedesignedLayout
			//TODO: translate
			title="Current Orders"
			//TODO: translate
			helmet={{ title: getPageTitle("Current Orders") }}
			className={styles.activeOrders}
		>
			<div className={styles.table}>
				<Table<IOrder>
					ref={tableRef}
					columns={columns}
					filters={filters}
					lastSticky
					//TODO: translate
					empty="No orders to show."
					lazyLoad="cursor"
					resetFilters
					defFetching
					footer="pagination"
					onFetch={handleFetch}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(ActiveOrders)
