/* eslint-disable no-return-assign,consistent-return */
import { DependencyList, EffectCallback, useEffect, useRef } from "react"

const useDidUpdate = (effect: EffectCallback, deps?: DependencyList) => {
	const firstRef = useRef<boolean>(true)

	useEffect(() => {
		setTimeout(() => (firstRef.current = false), 0)
	}, [])

	useEffect(() => {
		if (firstRef.current) return
		return effect()
	}, deps)
}

export default useDidUpdate
