import React from "react"
import { useIntl } from "react-intl"

import { routes } from "constants/routing"
import historyMessages from "messages/history"

import Switcher from "components/redesigned/Switcher"

import IconFile from "assets/icons/menu/file.svg"
import IconFileCheck from "assets/icons/menu/file-check.svg"
import IconCandleCheck from "assets/icons/menu/candle-check.svg"

import MenuSections from "../components/MenuSections"

const Orders: React.FC = () => {
	const { formatMessage } = useIntl()

	return (
		<>
			<Switcher items={[{ key: "orders", label: "Orders" }]} selected="orders" full />
			<MenuSections
				sections={[
					{
						items: [
							{
								route: routes.history.activeOrders,
								//TODO: translate
								caption: "Current Orders",
								icon: IconFile,
							},
							{
								route: routes.history.closedOrders,
								caption: formatMessage(historyMessages.order_history),
								icon: IconFileCheck,
							},
							{
								route: routes.history.tradesHistory,
								//TODO: translate
								caption: "Trading History",
								icon: IconCandleCheck,
							},
						],
					},
				]}
			/>
		</>
	)
}

export default Orders
