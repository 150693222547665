import React from "react"

import { rem } from "utils/styles"

type TProps = {
	size?: number
	color?: string
	className?: string
}

const SwitchHorizontal: React.FC<TProps> = ({ size = 24, color = "#fff", className }) => {
	const preparedColor = color?.startsWith("--") ? `var(${color})` : color
	return (
		<svg
			width={rem(size)}
			height={rem(size)}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M4 17H20M20 17L16 13M20 17L16 21M20 7H4M4 7L8 3M4 7L8 11"
				stroke={preparedColor}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export default SwitchHorizontal
