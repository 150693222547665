import {
	EBybitMessageTopic,
	EBybitMessageType,
	TBybitOrderbook,
	TBybitSocketMessage,
	TBybitWebSocketTrade,
} from "types/bybit"
import { TTicker, TTickerWS } from "types/ticker"
// import { useMobxStore } from "store"
import { useMst } from "models/Root"
import { IModelBybitTicker } from "models/Bybit"
import useWebSocket from "hooks/newUI/useWebSocket"
import useBybitWebSocket from "hooks/newUI/useBybitWebSocket"

export const useSocketConnection = (symbol: string) => {
	const {
		bybit: { setTicker, wsCredentials, patchRecentTrades },
		tickers: { updateTickers },
		terminal,
	} = useMst()

	// const {
	// 	terminal: { tickers },
	// } = useMobxStore()

	useBybitWebSocket({
		baseUrl: "wss://stream.bybit.com/v5/private",
		apiKey: wsCredentials?.api_key,
		expires: wsCredentials?.expires,
		signature: wsCredentials?.signature,
		channels: [EBybitMessageTopic.ORDER],
		disabled: !wsCredentials,
	})

	useWebSocket({
		url: EBybitMessageTopic.TICKERS,
		channels: [`${EBybitMessageTopic.TICKERS}.${symbol}`],
		onMessage: ({ topic, type, data }: TBybitSocketMessage<TTickerWS>) => {
			if (!topic?.startsWith?.(EBybitMessageTopic.TICKERS) || type !== EBybitMessageType.SNAPSHOT)
				return
			const ticker: Partial<TTicker> = {
				symbol: data.symbol,
				highPrice24h: +data.highPrice24h,
				lastPrice: +data.lastPrice,
				lowPrice24h: +data.lowPrice24h,
				prevPrice24h: +data.prevPrice24h,
				price24hPcnt: +data.price24hPcnt,
				turnover24h: +data.turnover24h,
				usdIndexPrice: +data.usdIndexPrice,
				volume24h: +data.volume24h,
			}
			setTicker(ticker as IModelBybitTicker)
			updateTickers(ticker)
			// tickers.setCurrent(data)
		},
	})

	useWebSocket({
		url: EBybitMessageTopic.ORDERBOOK,
		channels: [`${EBybitMessageTopic.ORDERBOOK}.${symbol}`],
		onMessage: ({ topic, type, data }: TBybitSocketMessage<TBybitOrderbook>) => {
			if (!topic?.startsWith?.(EBybitMessageTopic.ORDERBOOK)) return
			const { b: bids, a: asks } = data
			if (type === EBybitMessageType.SNAPSHOT) terminal.updateOrderBook(bids, asks)
			else if (type === EBybitMessageType.DELTA) terminal.patchOrderBook(bids, asks)
		},
	})

	useWebSocket({
		url: EBybitMessageTopic.PUBLIC_TRADE,
		channels: [`${EBybitMessageTopic.PUBLIC_TRADE}.${symbol}`],
		onMessage: ({ topic, type, data }: TBybitSocketMessage<TBybitWebSocketTrade[]>) => {
			if (!topic?.startsWith?.(EBybitMessageTopic.PUBLIC_TRADE)) return
			if (type === EBybitMessageType.SNAPSHOT) patchRecentTrades(data)
		},
	})
}
