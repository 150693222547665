import { messageError } from "utils/messages"

const getDetails = (obj: any): string | string[] => {
	if (typeof obj === "string") return obj

	if (obj && typeof obj === "object" && Object.keys(obj).length) {
		const details = obj.error_message || obj.error || obj.details || obj[Object.keys(obj)[0]]
		return getDetails(details)
	}

	if (obj instanceof Array) return obj.map(getDetails).flat()

	return "It looks like something went wrong. Please refresh the page and try again."
}

const errorHandler = (err: unknown, throwError = true) => {
	console.error("Error", err)
	if (typeof err === "object") {
		if ((err as Record<string, unknown>).status === 403) {
			messageError("Access denied [403]")
			return
		}

		if ((err as Record<string, unknown>).status === 401) {
			messageError("Unauthorized [401]")
			return
		}
	}

	const details = (err as Record<string, unknown>)?.data || err

	const message =
		typeof details === "object"
			? getDetails(details as Record<string, unknown>)
			: "It looks like something went wrong. Please, refresh the page."

	messageError(message)

	if (throwError) throw details
}

export default errorHandler
