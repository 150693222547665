import React from "react"
import { Navigate, Routes } from "react-router-dom"
import { Route } from "react-router"
import { PathRouteProps } from "react-router/dist/lib/components"

import { RenderModuleEnum } from "types/render"
import { URL_VARS } from "constants/routing"
import config from "helpers/config"

import ActiveOrders from "pages/History/ActiveOrders"
import ClosedOrders from "pages/History/ClosedOrders"
import TradesHistory from "pages/History/TradesHistory"
import Borrows from "./Borrows"

const History: React.FC = () => {
	const routesProps: PathRouteProps[] = [
		{
			path: URL_VARS.ACTIVE_ORDERS,
			element: <ActiveOrders />,
		},
		{
			path: URL_VARS.CLOSED_ORDERS,
			element: <ClosedOrders />,
		},
		{
			path: URL_VARS.TRADES_HISTORY,
			element: <TradesHistory />,
		},
		...(config.isModuleOn(RenderModuleEnum.MARGIN)
			? [
					{
						path: URL_VARS.BORROWS,
						element: <Borrows />,
					},
					// {
					// 	path: URL_VARS.REPAYMENTS,
					// 	element: <Repayments />,
					// },
					/*{
						path: URL_VARS.INTERESTS,
						element: <HistoryInterests />,
					},*/
					// {
					// 	path: URL_VARS.TRANSFERS,
					// 	element: <MarginTransfers />,
					// },
					// {
					// 	path: URL_VARS.MARGIN_CALLS,
					// 	element: <MarginCalls />,
					// },
					// {
					// 	path: URL_VARS.LIQUIDATIONS,
					// 	element: <Liquidations />,
					// },
			  ]
			: []),
		{
			path: URL_VARS.DEAD_END_ROUTE,
			element: <Navigate to={URL_VARS.ACTIVE_ORDERS} />,
		},
	]

	return (
		<Routes>
			{routesProps.map((props, i) => (
				<Route key={i} {...props} />
			))}
		</Routes>
	)
}

export default History
