/* eslint-disable no-useless-return,new-cap,consistent-return */
import { useEffect, useRef, useState } from "react"
import WebSocket from "websocket"

import config from "helpers/config";

const RECONNECT_MS = 5_000
const PING_MS = 30_000

type TWebSocketParams = {
	url: string
	channels: string[]
	disabled?: boolean
	onOpen?: () => void
	onClose?: (event: WebSocket.ICloseEvent) => void
	onError?: (error: Error) => void
	onMessage?: (data: any) => void
}

const useWebSocket = ({
	url,
	channels,
	disabled,
	onOpen,
	onClose,
	onError,
	onMessage,
}: TWebSocketParams) => {
	const wsRef = useRef<WebSocket.w3cwebsocket>()
	const closingRef = useRef<boolean>(false)
	const intervalRef = useRef<ReturnType<typeof setInterval>>()

	const [connected, setConnected] = useState<boolean>(false)

	const subscribe = () =>
		wsRef.current?.send(
			JSON.stringify({
				op: "subscribe",
				args: channels,
			}),
		)

	// const unsubscribe = () =>
	// 	wsRef.current?.send(
	// 		JSON.stringify({
	// 			op: "unsubscribe",
	// 			args: channels,
	// 		}),
	// 	)

	const ping = () =>
		wsRef.current?.send(
			JSON.stringify({
				op: "ping",
			}),
		)

	const connect = () => {
		closingRef.current = false
		wsRef.current = new WebSocket.w3cwebsocket(`${config.wsPrefix}/${url}`.replace(/\/+/g, "/"))

		wsRef.current.onclose = (event: WebSocket.ICloseEvent) => {
			clearInterval(intervalRef.current)
			setConnected(false)
			onClose?.(event)
			if (!closingRef.current) setTimeout(() => connect(), RECONNECT_MS)
		}

		wsRef.current.onopen = () => {
			setConnected(true)
			onOpen?.()
			subscribe()
			intervalRef.current = setInterval(ping, PING_MS)
		}

		wsRef.current.onerror = (error: Error) => onError?.(error)

		wsRef.current.onmessage = (message: WebSocket.IMessageEvent) =>
			onMessage?.(JSON.parse(message.data as string))
	}

	const disconnect = () => {
		console.log("disconnect")
		closingRef.current = true
		wsRef.current?.close()
		wsRef.current = undefined
	}

	useEffect(() => {
		if (disabled || !channels.length) return
		connect()
		return disconnect
	}, [url, channels.join(), disabled])

	return connected
}

export default useWebSocket
