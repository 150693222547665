import { useIntl } from "react-intl"

import { messageInfo } from "utils/messages"
import commonMessages from "messages/common"

const useCopyClick = () => {
	const { formatMessage } = useIntl()
	return (text: string | number, message?: string) => {
		window.navigator.clipboard.writeText(text.toString())
		messageInfo(message || formatMessage(commonMessages.copied))
	}
}

export default useCopyClick
