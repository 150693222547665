import React, { useRef } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"

import { IOrder } from "models/History"
import HistoryService from "services/HistoryService"
import { getPageTitle } from "helpers/global"

import RedesignedLayout from "layouts/RedesignedLayout"
import Table, { TTableOnFetch, TTableRef } from "components/redesigned/Table"

import { useColumns, useFilters } from "./closedOrders.hooks"
import styles from "./closedOrders.module.scss"

const ClosedOrders: React.FC = () => {
	const { formatMessage } = useIntl()

	const columns = useColumns()
	const filters = useFilters()

	const handleFetch: TTableOnFetch<IOrder> = async ({ cursor, limit, filters }) => {
		const { results: data, cursor: next } = await HistoryService.getOrdersHistory({
			category: "spot",
			symbol: filters.symbol,
			limit,
			cursor,
		})
		return { data, next }
	}

	return (
		<RedesignedLayout
			//TODO: translate
			title="Order History"
			//TODO: translate
			helmet={{ title: getPageTitle("Order History") }}
			className={styles.closedOrders}
		>
			<div className={styles.table}>
				<Table<IOrder>
					columns={columns}
					filters={filters}
					lastSticky
					//TODO: translate
					empty="No trades to show."
					lazyLoad="cursor"
					resetFilters
					defFetching
					footer="pagination"
					onFetch={handleFetch}
				/>
			</div>
		</RedesignedLayout>
	)
}

export default observer(ClosedOrders)
