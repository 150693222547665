/* eslint-disable react/no-unescaped-entities */
import React from "react"
import { useIntl } from "react-intl"

import Collapser from "components/redesigned/Collapser"

const DepositQuestions: React.FC = () => {
	const { formatMessage } = useIntl()

	// TODO: translate
	return (
		<>
			<Collapser label="How Do I Invite Other Users To The Crypto Trading Platform?">
				<p>
					Data on your referrals can be viewed in the User Account in the «My referrals» tab. There
					you can also find an invite link, which your friends and acquaintances will need to follow
					to register on the platform. Information about the bidding of your referrals and your
					income from them can also be found in this tab.
				</p>
			</Collapser>
			<Collapser label="What Bonuses Does Participation In The Referral Program Give?">
				<p>Bonuses include:</p>
				<ol>
					<li>
						<b>
							A 50% discount on the trading commission charged by the exchange for trading
							operations.
						</b>
						The bonus is valid for 30 days after the referral's first trade. During this period, the
						referral is considered active. Then you can invite other referrals, the number of which
						is not limited.
					</li>
					<li>
						<b>Passive income equal to 50% of your referral's trading commission</b> (buying and
						selling currencies). This option is also only available within 30 days of the referral's
						first trade.
					</li>
				</ol>
				<p>
					Entering the BTC-Alpha system as someone's referral is beneficial in that you immediately
					receive a discount on the commission, and the sooner you invite your own referrals, the
					faster the second bonus will become available to you. The exchange reserves the right to
					make changes to the referral program at its sole discretion.
				</p>
			</Collapser>
			<Collapser label="Referral Program Conditions">
				<p>
					You can take part in the referral program as a referrer (a user who has invited a friend)
					and a referral (a user, who has accepted an invitation from another user).
				</p>
				<p>
					<b>In order to become a referrer</b>, you need to be a registered user and share an invite
					link with a friend or acquaintance who will register on BTC-Alpha using this link.
				</p>
				<p>
					<b>To become a referral</b>, you need to sign up using someone's invite. As soon as the
					referral is registered, he/she can become a referrer himself and invite other users to
					register on BTC-Alpha. Immediately after registration, a user can see a fold «My
					referrals» in his/her account, where there will be an invite link that he can send to
					other users.
				</p>
			</Collapser>
		</>
	)
}

export default DepositQuestions
