import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { observer } from "mobx-react-lite"
import { QueryClient, QueryClientProvider } from "react-query"
import { SkeletonTheme } from "react-loading-skeleton"
import { useLocation } from "react-router-dom"
import { Slide, ToastContainer } from "react-toastify"

import "react-loading-skeleton/dist/skeleton.css"
import "react-toastify/dist/ReactToastify.css"

import { ThemeEnum } from "types/theme"
import { EBybitMessageTopic } from "types/bybit"
import { useMobxStore } from "store"
import { useMst } from "models/Root"
import config from "helpers/config"
import useMigrationNotificationModal from "hooks/useMigrationNotificationModal"
import useSalesDoubler from "hooks/useSalersDoubler"
import useBybitWebSocket from "hooks/newUI/useBybitWebSocket"

import Router from "./Router"

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})

console.log(
	"%cThis browser feature is for developers. If someone told you to write here any data, they are scammers. If you paste data, you will give scammers access to your account.",
	"font-size: 26px; color: #bada55",
)

const App: React.FC = () => {
	const {
		account: { wallet },
	} = useMobxStore()
	const { global, account, notifications, bybit } = useMst()
	const { openMigrationNotificationModal } = useMigrationNotificationModal()
	const { pathname } = useLocation()
	const salesDoubler = useSalesDoubler()

	const zoomDisable = (e: Event) => {
		e.preventDefault()
		// @ts-ignore
		document.body.style.zoom = 1
	}

	useEffect(() => {
		global.loadDepartmentInfo()

		if (config.salesDoubler) {
			salesDoubler.sendRegister()
		}

		document.addEventListener("gesturestart", zoomDisable)
		document.addEventListener("gesturechange", zoomDisable)
		document.addEventListener("gestureend", zoomDisable)
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	useEffect(() => {
		if (global.isAuthenticated) {
			account.loadBalances()
			account.loadProfileStatus()
			account.loadMigratorStatus().then(response => {
				console.log("Migrator status response:", response)
				if (response?.status === "migrated_not_notified") {
					openMigrationNotificationModal()
				}
			})
			notifications.loadLatestNotifications()
			bybit.loadWsCredentials()
			wallet.load()
		} else {
			wallet.clear()
		}
	}, [global.isAuthenticated])

	useBybitWebSocket({
		baseUrl: "wss://stream.bybit.com/v5/private",
		apiKey: bybit.wsCredentials?.api_key,
		expires: bybit.wsCredentials?.expires,
		signature: bybit.wsCredentials?.signature,
		channels: [EBybitMessageTopic.WALLET],
		disabled: !global.isAuthenticated || !bybit.wsCredentials,
	})

	useEffect(() => {
		document.body.setAttribute("data-theme", ThemeEnum.Dark)
		document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#1a1c1f")
	}, [])

	return (
		<div className="main">
			<Helmet htmlAttributes={{ lang: global.locale }} />
			<QueryClientProvider client={queryClient}>
				<SkeletonTheme
					baseColor="var(--skeleton-base-color)"
					highlightColor="var(--skeleton-highlight-color)"
				>
					<Router />
				</SkeletonTheme>
			</QueryClientProvider>
			<ToastContainer
				position="bottom-left"
				autoClose={5000}
				hideProgressBar
				transition={Slide}
				newestOnTop={false}
				closeOnClick
				theme="dark"
				style={{ zIndex: 999999 }}
			/>
		</div>
	)
}

export default observer(App)
