import React, { JSX } from "react"

import { TAlphaIcon } from "types/alphaCodes"

export type TTableRef = {
	refetch: () => Promise<void>
}

export type TTableCellArgs<Row> = [Row, string, number]

export type TTableRender<Row> = (
	...args: TTableCellArgs<Row>
) => JSX.Element | string | number | null

export type TTableHandlerActionClick<Row> = (...args: TTableCellArgs<Row>) => void

export type TTableAction<Row> = {
	icon?: TAlphaIcon | React.ReactNode
	caption?: string
	visible?: boolean
	disabled?: boolean
	to?: string
	onClick?: TTableHandlerActionClick<Row>
}

export type TTableGetActions<Row> = (
	...args: TTableCellArgs<Row>
) => Array<TTableAction<Row>> | false | undefined | null

export enum ETableColumnAlign {
	TOP = "top",
	MIDDLE = "middle",
	BOTTOM = "bottom",
}

export type TTableColumn<Row> = {
	key: keyof Row | string
	caption?: string | React.ReactNode
	width?: number | `${number}%`
	align?: ETableColumnAlign
	subline?: TTableRender<Row>
	render?: TTableRender<Row>
	actions?: TTableGetActions<Row>
	className?: string
}

export type TTableColumns<Row> = Array<TTableColumn<Row>>

export enum ETableFilterType {
	STRING = "string",
	SELECT = "select",
	CHECKBOX = "checkbox",
	DATE = "date",
}

type TTableFilterString = {
	type: ETableFilterType.STRING
	placeholder?: string
	icon?: TAlphaIcon
}

type TTableFilterSelect = {
	type: ETableFilterType.SELECT
	caption?: string
	items?: Array<{ value: string | number; label: string | number }>
}

type TTableFilterCheckbox = {
	type: ETableFilterType.CHECKBOX
	caption?: string
}

type TTableFilterDate = {
	type: ETableFilterType.DATE
	caption?: string
	minDate?: Date
	maxDate?: Date
}

export type TTableFilter<Row> = {
	key: keyof Row | string
	toRight?: boolean
	filter?: (value: any, ...args: TTableCellArgs<Row>) => boolean
} & (TTableFilterString | TTableFilterSelect | TTableFilterCheckbox | TTableFilterDate)

export type TTableFilters<Row> = Array<TTableFilter<Row>>

export type TTableFiltration<Row> = Record<keyof Row | string, any>

export type TTableOnFetch<Row> = (args: {
	limit: number
	page: number
	cursor: string
	filters: TTableFiltration<Row>
}) => Promise<{
	data: Row[]
	count?: number
	next?: string
}>

export type TTableFooter = false | "pagination" | "all"
