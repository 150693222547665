/* eslint-disable no-case-declarations,no-void,no-unused-expressions,react/button-has-type,jsx-a11y/control-has-associated-label */
import React, {
	CSSProperties,
	FormEventHandler,
	forwardRef,
	Ref,
	useEffect,
	useId,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react"
import Skeleton from "react-loading-skeleton"
import cn from "classnames"

import { getScrollbarIsOverlay } from "utils/scroll"

import Input from "components/redesigned/Input"
import Select, { TSelectChangeEvent } from "components/redesigned/Select"
import Button from "components/redesigned/Button"
import MiniButton from "components/redesigned/MiniButton"
import CheckBox from "components/redesigned/CheckBox"
import DateInput, { dateToValue } from "components/redesigned/DateInput"

import EmptyImg from "assets/images/common/empty.png"

import {
	ETableColumnAlign,
	ETableFilterType,
	TTableColumn,
	TTableFilter,
	TTableFiltration,
	TTableFooter,
	TTableOnFetch,
	TTableRef,
} from "./types"
import { DEFAULT_LIMIT, limits } from "./constants"
import styles from "./table.module.scss"

type TProps<Row extends Record<string, any> = Record<string, any>> = {
	id?: keyof Row
	columns: TTableColumn<Row>[]
	data?: Row[]
	filters?: TTableFilter<Row>[]
	resetFilters?: boolean
	loading?: boolean
	defFetching?: boolean
	defLimit?: (typeof limits)[number]
	empty?: string
	firstSticky?: boolean
	lastSticky?: boolean
	lazyLoad?: boolean | "cursor"
	footer?: TTableFooter
	onFetch?: TTableOnFetch<Row>
	onAllClick?: () => void
	className?: string
}

type TParams = {
	count: number
	limit: number
	page: number
	cursor: string
	filtration: Array<[string, any]>
}

const Component = <Row extends Record<string, any>>(
	{
		id = "id",
		columns,
		data,
		filters,
		resetFilters,
		loading,
		defFetching,
		defLimit = DEFAULT_LIMIT,
		empty,
		firstSticky,
		lastSticky,
		lazyLoad,
		footer,
		onFetch,
		onAllClick,
		className,
	}: TProps<Row>,
	ref: Ref<TTableRef>,
) => {
	const tableId = useId()
	const rootRef = useRef<HTMLDivElement>(null)
	const headerRef = useRef<HTMLDivElement>(null)
	const formRef = useRef<HTMLFormElement>(null)
	const contentRef = useRef<HTMLDivElement>(null)
	const scrollRef = useRef<HTMLDivElement>(null)
	const footerRef = useRef<HTMLDivElement>(null)

	const [fetched, setFetched] = useState<Row[]>([])
	const [fetching, setFetching] = useState<boolean>(!!defFetching)

	const isLoading = fetching || loading
	const isCursor = lazyLoad === "cursor"

	const [{ count, limit, page, cursor, filtration }, _setParams] = useState<TParams>({
		count: lazyLoad ? 0 : data?.length || 0,
		limit: defLimit,
		page: 1,
		cursor: "",
		filtration: [],
	})
	const updateParams = (data: Partial<TParams>) => _setParams(value => ({ ...value, ...data }))

	/* Filters */

	const leftFilters = useMemo(() => filters?.filter(({ toRight }) => !toRight), [filters])
	const rightFilters = useMemo(() => filters?.filter(({ toRight }) => toRight), [filters])

	const handleFiltersSubmit: FormEventHandler<HTMLFormElement> = event => event.preventDefault()

	const handleFiltersChange: FormEventHandler<HTMLFormElement> = ({ target }) =>
		setTimeout(() => {
			if (target instanceof HTMLInputElement && !target.name) return
			const nodeForm = formRef.current as HTMLFormElement
			const formData = new FormData(nodeForm)
			const values = Array.from(formData.entries())
				.map(field => {
					const [key, value] = field as [string, string]
					const filter = filters?.find(filter => filter.key === key)
					if (!filter || !value) return [key, null]
					switch (filter.type) {
						case ETableFilterType.STRING:
							return [key, value.toLowerCase().trim()]
						case ETableFilterType.SELECT:
							return [key, filter.items?.[+value]?.value]
						case ETableFilterType.CHECKBOX:
							return [key, !!value]
						case ETableFilterType.DATE:
							return [key, value]
						default:
							return [key, null]
					}
				})
				.filter(([, value]) => !!value)
			updateParams({ filtration: values as TParams["filtration"], page: 1, cursor: "" })
		}, 0)

	const renderFilter = (filter: TTableFilter<Row>) => {
		const { key, type } = filter
		return (
			<div key={`table-${tableId}-filter-${key as string}`}>
				{type === ETableFilterType.STRING && (
					<Input
						className={styles.text}
						name={key as string}
						placeholder={filter.placeholder}
						icon={filter.icon}
						mini
					/>
				)}
				{type === ETableFilterType.SELECT && (
					<Select
						mini
						search
						placeholder={filter.caption}
						name={key as string}
						options={filter.items?.map(({ label }, index) => ({
							label,
							value: index,
						}))}
						controlClassName={styles.select}
					/>
				)}
				{type === ETableFilterType.CHECKBOX && (
					<CheckBox name={key as string} className={styles.checkbox}>
						{filter.caption}
					</CheckBox>
				)}
				{type === ETableFilterType.DATE && (
					<DateInput
						placeholder={filter.caption}
						name={key as string}
						range
						minDate={filter.minDate}
						maxDate={filter.maxDate}
					/>
				)}
			</div>
		)
	}

	const filtered = useMemo<Row[] | undefined>(() => {
		if (lazyLoad) return undefined
		if (!data?.length) return []
		if (!filters?.length || !filtration.length) return data
		return data.filter((row, index) =>
			filtration.every(([key, value]) => {
				const filter = filters?.find(filter => filter.key === key)
				if (filter?.filter) return filter.filter(value, row, key, index)
				switch (filter?.type) {
					case ETableFilterType.STRING:
						return row[key].toString().toLowerCase().includes(value)
					case ETableFilterType.SELECT:
						return row[key] === value
					case ETableFilterType.CHECKBOX:
						return !!row[key] === value
					case ETableFilterType.DATE:
						const date = new Date(row[key])
						if (Number.isNaN(date.getTime())) return false
						const dateValue = dateToValue(date)
						const [min, max] = value.split(",")
						return min <= dateValue && dateValue <= max
					default:
						return true
				}
			}),
		)
	}, [lazyLoad, data, data?.length, filters, filtration])

	/* Pagination */

	const pages = Math.ceil(count / limit)

	useEffect(() => updateParams({ count: filtered?.length || 0 }), [filtered, lazyLoad])
	useEffect(() => {
		pages < page &&
			updateParams({
				page: Math.max(pages, 1),
			})
	}, [pages])

	const handleSelectLimit: TSelectChangeEvent<number> = limit =>
		updateParams({
			limit: limit || limits[0],
			page: 1,
			cursor: "",
		})

	const fetchPage = async (first?: boolean) => {
		if (!lazyLoad || !onFetch) return
		setFetching(true)
		try {
			const result = await onFetch({
				page: first ? 1 : page,
				cursor: first ? "" : cursor,
				limit,
				filters: Object.fromEntries(filtration) as TTableFiltration<Row>,
			})
			if (isCursor) {
				setFetched(result.data || [])
				updateParams({
					cursor: result.next || "",
					page: first ? 1 : page + 1,
				})
			} else if (result.count !== undefined) {
				setFetched(result.data || [])
				updateParams({ count: result.count, cursor: "" })
			}
		} finally {
			setFetching(false)
		}
	}

	const goPageHandler = (nextPage: number) => () => updateParams({ page: nextPage })

	const handleGoPrevious = () =>
		isCursor ? fetchPage(true) : updateParams({ page: Math.max(page - 1, 1), cursor: "" })

	const handleGoNext = () =>
		isCursor ? fetchPage() : updateParams({ page: Math.min(page + 1, pages) })

	const rows = useMemo<Row[]>(() => {
		if (isLoading) return [...Array(limit)].map((_, index) => ({ [id]: index } as Row))
		if (lazyLoad) return fetched
		if (footer !== "pagination") return filtered || []
		return filtered?.slice((page - 1) * limit, page * limit) || []
	}, [lazyLoad, fetched, filtered, footer, limit, page, isLoading])

	useEffect(() => {
		lazyLoad !== "cursor" && fetchPage()
	}, [lazyLoad, limit, page, filtration, !onFetch])

	useEffect(() => {
		lazyLoad === "cursor" && fetchPage(true)
	}, [lazyLoad, limit, filtration, !onFetch])

	/* Scroll */

	const [isScrollOverlay, setScrollOverlay] = useState(getScrollbarIsOverlay())

	const getHeaderClassName = (isFull?: boolean) => cn(styles.header, { [styles.full]: isFull })
	const getScrollClassName = (isFull?: boolean) =>
		cn(styles.scroll, {
			[styles.overlay]: isScrollOverlay,
			[styles.full]: isFull,
		})
	const getFooterClassName = (isFull?: boolean) =>
		cn(styles.footer, {
			[styles.pagination]: footer === "pagination",
			[styles.full]: isFull,
		})

	useEffect(() => {
		const nodeRoot = rootRef.current as HTMLDivElement
		const nodeHeader = headerRef.current as HTMLDivElement
		const nodeContent = contentRef.current as HTMLDivElement
		const nodeTable = nodeContent.children[0] as HTMLTableElement
		const nodeThead = nodeTable.querySelector("thead") as HTMLTableSectionElement
		const nodeScroll = scrollRef.current as HTMLDivElement
		const nodeExpander = nodeScroll.children[0] as HTMLDivElement
		const nodeFooter = footerRef.current as HTMLDivElement | null

		const checkScroll = () => {
			setScrollOverlay(getScrollbarIsOverlay())

			nodeScroll.scrollTo({ left: nodeContent.scrollLeft })

			nodeContent.className = cn(styles.content, {
				[styles.firstStuck]: firstSticky && nodeContent.scrollLeft > 0,
				[styles.lastStuck]:
					lastSticky &&
					nodeContent.scrollWidth - nodeContent.clientWidth - nodeContent.scrollLeft > 0,
			})

			if (nodeContent.clientWidth < nodeContent.scrollWidth) {
				nodeExpander.style.setProperty(
					"width",
					`${nodeContent.scrollWidth - (nodeContent.offsetWidth - nodeScroll.offsetWidth)}px`,
				)
				nodeScroll.style.removeProperty("display")
			} else {
				nodeScroll.style.setProperty("display", "none")
				nodeExpander.style.removeProperty("width")
			}
		}

		const setScroll = () => nodeContent.scrollTo({ left: nodeScroll.scrollLeft })

		const checkHeader = () => {
			const footerHeight = (footer && nodeFooter?.offsetHeight) || 0
			nodeHeader.className = getHeaderClassName(nodeHeader.offsetTop - nodeRoot.offsetTop > 0)
			nodeScroll.className = getScrollClassName(
				nodeContent.offsetTop + nodeContent.offsetHeight - nodeScroll.offsetTop >
					nodeExpander.offsetHeight,
			)
			nodeScroll.style.setProperty("bottom", `${footerHeight}px`)
			nodeFooter &&
				(nodeFooter.className = getFooterClassName(
					nodeFooter.offsetTop +
						nodeFooter.offsetHeight -
						(nodeRoot.offsetTop + nodeRoot.offsetHeight) <
						0,
				))

			const translate = nodeHeader.offsetTop + nodeHeader.offsetHeight - nodeTable.offsetTop
			if (translate) nodeThead.style.setProperty("transform", `translateY(${translate}px)`)
			else nodeThead.style.removeProperty("transform")
		}

		const handleScroll = ({ target }: Event) => {
			if (target === nodeScroll) setScroll()
			else if (target === nodeContent) checkScroll()
			checkHeader()
		}

		setTimeout(() => {
			checkScroll()
			checkHeader()
		}, 1000)

		window.addEventListener("scroll", handleScroll, true)
		window.addEventListener("resize", checkScroll)
		return () => {
			window.removeEventListener("scroll", handleScroll, true)
			window.removeEventListener("resize", checkScroll)
		}
	}, [columns.length, rows?.length, firstSticky, lastSticky, footer])

	/* Render */

	useImperativeHandle(ref, () => ({
		refetch: fetchPage,
	}))

	return (
		<div ref={rootRef} className={cn(styles.table, { [styles.loading]: isLoading }, className)}>
			<div ref={headerRef} className={getHeaderClassName()}>
				{filters?.length && (
					<form
						ref={formRef}
						onReset={handleFiltersChange}
						onInput={handleFiltersChange}
						onSubmit={handleFiltersSubmit}
					>
						{!!leftFilters?.length && <div>{leftFilters.map(renderFilter)}</div>}
						{!!rightFilters?.length && (
							<div className={styles.right}>{rightFilters.map(renderFilter)}</div>
						)}
						{resetFilters && (
							<MiniButton
								type="reset"
								caption="Reset"
								disabled={!filtration.length}
								className={styles.reset}
							/>
						)}
					</form>
				)}
			</div>
			<div ref={contentRef} className={styles.content}>
				<table
					className={cn({
						[styles.firstSticky]: firstSticky,
						[styles.lastSticky]: lastSticky,
					})}
				>
					<thead>
						<tr>
							{columns.map(({ key, caption, width }) => {
								const style: CSSProperties = {}
								if (width) {
									const tdWidth = typeof width === "number" ? `${width}px` : width
									style.minWidth = tdWidth
									style.width = tdWidth
									style.maxWidth = tdWidth
								}
								return (
									<td key={`table-${tableId}-head-${key as string}`} style={style}>
										{caption}
									</td>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index) => (
							<tr key={`table-${tableId}-row-${row[id]}`}>
								{columns.map(({ key, render, subline, align, actions, className }) => (
									<td
										key={`table-${tableId}-row-${row[id]}-cell-${key as string}`}
										className={cn(
											{
												[styles.middle]: align === ETableColumnAlign.MIDDLE,
												[styles.bottom]: align === ETableColumnAlign.BOTTOM,
											},
											className,
										)}
									>
										{isLoading ? (
											<Skeleton className={styles.skeleton} />
										) : (
											<>
												<div>
													{render ? render(row, key as string, index) : row[key]}
													{(actions?.(row, key as string, index) || undefined)?.map(
														({ icon, caption, visible, disabled, to, onClick }, actionIndex) => {
															if (visible === false) return null
															return (
																<Button
																	key={`table-${tableId}-row-${row[id]}-cell-${
																		key as string
																	}-action-${actionIndex.toString()}`}
																	icon={icon}
																	caption={caption}
																	disabled={disabled}
																	size="mini"
																	kind="outlined"
																	loading="auto"
																	to={to}
																	onClick={() => onClick?.(row, key as string, index)}
																/>
															)
														},
													)}
												</div>
												{subline && (
													<div className={styles.subline}>{subline(row, key as string, index)}</div>
												)}
											</>
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{!isLoading && !rows.length && (
				<div className={styles.empty}>
					<img src={EmptyImg} alt="Empty" />
					<div>
						<span>{empty}</span>
						{/*TODO: translate*/}
						<span>No activities to display.</span>
					</div>
				</div>
			)}
			<div ref={scrollRef} className={getScrollClassName()}>
				<div />
			</div>
			{(!!footer || isScrollOverlay) && (
				<div ref={footerRef} className={getFooterClassName()}>
					{footer === "pagination" && (
						<>
							<Select<number>
								mini
								//TODO: translate
								options={limits.map(limit => ({ label: `Show by ${limit}`, value: limit }))}
								value={limit}
								disabled={!isCursor && count <= limits[0]}
								controlClassName={styles.limits}
								onChange={handleSelectLimit}
							/>
							<div>
								{Array(pages)
									.fill(null)
									.map((_, index) => (
										<MiniButton
											key={`table-${tableId}-page-${index.toString()}`}
											caption={index + 1}
											disabled={index + 1 === page}
											onClick={goPageHandler(index + 1)}
										/>
									))}
							</div>
							<div>
								<button type="button" disabled={page <= 1} onClick={handleGoPrevious}>
									{isCursor ? (
										//TODO: translate
										<span>Go to first</span>
									) : (
										<i className="ai ai-chevron_left" />
									)}
								</button>
								<hr />
								<button
									type="button"
									disabled={isCursor ? !cursor : page >= pages}
									onClick={handleGoNext}
								>
									<i className="ai ai-chevron_right" />
								</button>
							</div>
						</>
					)}
					{footer === "all" && (
						// TODO: translate
						<MiniButton caption="View All" onClick={onAllClick} />
					)}
				</div>
			)}
		</div>
	)
}

const Table = forwardRef(Component) as <Row extends Record<string, any>>(
	props: TProps<Row> & { ref?: Ref<TTableRef> },
) => ReturnType<typeof Component>

export default Table
