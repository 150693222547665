export enum ETickersQuotedEnum {
	FIAT = "FIAT",
	FAVORITES = "Favorites",
}

export type TTicker = {
	ask1Price: number
	ask1Size: number
	base_coin: string
	bid1Price: number
	bid1Size: number
	highPrice24h: number
	lastPrice: number
	lowPrice24h: number
	prevPrice24h: number
	price24hPcnt: number
	price_precision: number
	quote_coin: string
	symbol: string
	turnover24h: number
	usdIndexPrice: number
	volume24h: number
}

export type TTickerWS = {
	symbol: string
	highPrice24h: string
	lastPrice: string
	lowPrice24h: string
	prevPrice24h: string
	price24hPcnt: string
	turnover24h: string
	usdIndexPrice: string
	volume24h: string
}
