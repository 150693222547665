/* eslint-disable consistent-return,jsx-a11y/control-has-associated-label,no-unused-expressions,no-void,no-proto,@typescript-eslint/no-inferrable-types */
import React, { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react"
import cn from "classnames"

import useFormField from "hooks/useFormField"
import { rem } from "utils/styles"

import Tabs, { TTabsItem } from "components/redesigned/Tabs"
import Calendar, { ECalendarType, TEdgeDay, TOnSelect } from "components/redesigned/Calendar"

import { TDateInputValue } from "./types"
import {
	dateToStr,
	dateToValue,
	getDaysBetween,
	getFirstMonthDay,
	getLastMonthDay,
	getMonthsBetween,
} from "./utils"

import styles from "./dateInput.module.scss"

type TProps<Range extends boolean> = {
	name?: string
	placeholder?: string
	range?: Range
	minDate?: TEdgeDay
	maxDate?: TEdgeDay
	value?: TDateInputValue<Range>
	defValue?: TDateInputValue<Range>
	disabled?: boolean
	className?: string
	onChange?: (value: null | TDateInputValue<Range>) => void
}

export default function DateInput<Range extends boolean = false>({
	name,
	placeholder,
	range,
	minDate = getFirstMonthDay(),
	maxDate = getLastMonthDay(),
	value,
	defValue,
	disabled,
	className,
	onChange,
}: TProps<Range>) {
	const rootRef = useRef<HTMLDivElement>(null)
	const clearRef = useRef<HTMLInputElement>(null)
	const calendarRef = useRef<HTMLInputElement>(null)

	const [opened, setOpened] = useState<boolean>(false)
	const [top, setTop] = useState<boolean>(false)

	const toggle = () => setOpened(val => !val)
	const close = () => setOpened(false)

	useEffect(() => void (!opened && setTop(false)), [opened])

	const handleViewChange = () =>
		setTimeout(() => {
			if (top) return
			const root = rootRef.current as HTMLDivElement
			const calendar = calendarRef.current as HTMLDivElement
			const rootRect = root.getBoundingClientRect()
			const calendarRect = calendar.getBoundingClientRect()
			setTop(rootRect.bottom + calendarRect.height > window.innerHeight)
		}, 0)

	useEffect(() => {
		if (!opened) return
		handleViewChange()
		const handleClick = ({ target }: MouseEvent | TouchEvent) =>
			!rootRef.current?.contains(target as HTMLElement) && close()
		const handleKeyDown = ({ key }: KeyboardEvent) => key === "Escape" && close()

		window.addEventListener("keydown", handleKeyDown)
		window.addEventListener("mousedown", handleClick)
		window.addEventListener("touchstart", handleClick)

		return () => {
			window.removeEventListener("keydown", handleKeyDown)
			window.removeEventListener("mousedown", handleClick)
			window.removeEventListener("touchstart", handleClick)
		}
	}, [opened])

	const [tab, setTab] = useState<ECalendarType>(ECalendarType.DAY)
	const tabs = useMemo<TTabsItem<ECalendarType>[]>(
		() => [
			{
				key: ECalendarType.DAY,
				label: "Day",
			},
			{
				key: ECalendarType.WEEK,
				label: "Week",
			},
			{
				key: ECalendarType.MONTH,
				label: "Month",
			},
		],
		[],
	)

	const [selected, setSelected] = useState<null | TDateInputValue<Range>>(null)

	const firstStr = !selected
		? ""
		: dateToStr(
				range ? (selected as TDateInputValue<true>)[0] : (selected as TDateInputValue<false>),
		  )
	const lastStr = !selected
		? ""
		: dateToStr(
				range ? (selected as TDateInputValue<true>)[1] : (selected as TDateInputValue<false>),
		  )

	const updateSelected = (newValue?: TDateInputValue<Range>, force: boolean = false) => {
		setTimeout(() => onChange?.(newValue || null), 0)
		close()

		if (value !== undefined && !force) return

		setSelected(newValue || null)

		let inputValue = ""
		if (newValue && !range) {
			inputValue = dateToValue(newValue as TDateInputValue<false>)
		} else if (newValue && range) {
			inputValue = `${dateToValue((newValue as TDateInputValue<true>)[0])},${dateToValue(
				(newValue as TDateInputValue<true>)[1],
			)}`
		}

		setInputValue(inputValue)
	}

	const clearValue = () => updateSelected()
	const resetValue = () => updateSelected(value !== undefined ? value : defValue, true)

	const { setInputValue } = useFormField({ name, rootRef, onReset: resetValue })

	useEffect(() => updateSelected(value, true), [value])
	useEffect(() => void (value === undefined && updateSelected(defValue)), [defValue])

	const handleClick: MouseEventHandler<HTMLButtonElement> = ({ target }) => {
		if (clearRef.current?.firstChild?.contains(target as HTMLElement)) clearValue()
		else toggle()
	}

	const handleSelect: TOnSelect = ({ selected, value: newValue }) => {
		if (!selected) return
		updateSelected(newValue as TDateInputValue<Range>)
	}

	const caption = useMemo<string>(() => {
		if (!selected) return ""
		if (range)
			return `${dateToStr((selected as TDateInputValue<true>)[0])} - ${dateToStr(
				(selected as TDateInputValue<true>)[1],
			)}`
		return dateToStr(selected as TDateInputValue<false>)
	}, [selected])

	let periodCount: number | string =
		!range || !selected
			? 0
			: tab === ECalendarType.MONTH
			? getMonthsBetween(
					(selected as TDateInputValue<true>)[0],
					(selected as TDateInputValue<true>)[1],
			  )
			: getDaysBetween(
					(selected as TDateInputValue<true>)[0],
					(selected as TDateInputValue<true>)[1],
			  )

	if (periodCount > 0)
		periodCount = tab === ECalendarType.MONTH ? `${periodCount} months` : `${periodCount} days`

	return (
		<div ref={rootRef} className={cn(styles.dateInput, className)}>
			<button
				type="button"
				disabled={disabled}
				className={cn({ [styles.opened]: opened })}
				onClick={handleClick}
			>
				<i className="ai ai-calendar" />
				<span>{caption || placeholder}</span>
				{!!selected && (
					<div ref={clearRef} className={styles.clear}>
						<div>
							<i className="ai ai-x_close" />
						</div>
					</div>
				)}
			</button>
			{opened && (
				<div
					ref={calendarRef}
					className={cn(styles.popup, {
						[styles.top]: top,
						[styles.month]: tab === ECalendarType.MONTH,
					})}
				>
					{range && (
						<div className={styles.tabs}>
							<Tabs items={tabs} compact selected={tab} onClick={setTab} />
						</div>
					)}
					<Calendar
						minDate={minDate}
						maxDate={maxDate}
						range={range}
						className={styles.calendar}
						calendarType={tab}
						value={selected}
						onSelect={handleSelect}
					/>
					{range && (
						<div className={styles.selected}>
							<span>Selected period:</span>
							<strong>{periodCount || "–"}</strong>
						</div>
					)}
					<div className={styles.value}>
						<strong>{firstStr || "–"}</strong>
						{range && (
							<>
								<span>–</span>
								<strong>{lastStr || "–"}</strong>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	)
}
